import { ACTIONS } from '../constants/competencies';
import { MODE } from '../constants/mode';

const INITIAL_STATE = {
  competenciesList: { competencies: [], params: {}, pager: null, error: null, loading: false },
  activeCompetence: { competence: null, error: null, mode: null },
};

export const competencies = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.COMPETENCE_CREATE_RESET:
    case ACTIONS.COMPETENCE_UPDATE_RESET:
    case ACTIONS.COMPETENCE_DELETE_RESET:
    case ACTIONS.COMPETENCE_FETCH_RESET:
      return {
        ...state,
        activeCompetence: {
          competence: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //================================== COMPETENCE CREATE START ==================================//
    case ACTIONS.COMPETENCE_CREATE_START:
      return {
        ...state,
        activeCompetence: {
          competence: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.COMPETENCE_CREATE_FAILURE:
      return {
        ...state,
        activeCompetence: {
          competence: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.COMPETENCE_CREATE_SUCCESS:
      return {
        ...state,
        competenciesList: {
          ...state.competenciesList,
          competencies: state.competenciesList.competencies.concat(action.payload),
          error: null,
          loading: false,
        },
        activeCompetence: {
          competence: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    //==================================  COMPETENCE CREATE END  ==================================//
    //================================== COMPETENCE UPDATE START ==================================//
    case ACTIONS.COMPETENCE_UPDATE_START:
      return {
        ...state,
        activeCompetence: {
          competence: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.COMPETENCE_UPDATE_FAILURE:
      return {
        ...state,
        activeCompetence: {
          competence: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.COMPETENCE_UPDATE_SUCCESS:
      return {
        ...state,
        competenciesList: {
          ...state.competenciesList,
          competencies: state.competenciesList.competencies.map(perm => {
            if (perm.id === action.payload.id) {
              perm = action.payload;
            }
            return perm;
          }),
          error: null,
          loading: false,
        },
        activeCompetence: {
          competence: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  COMPETENCE UPDATE END  ==================================//
    //================================== COMPETENCE DELETE START ==================================//
    case ACTIONS.COMPETENCE_DELETE_START:
      return {
        ...state,
        activeCompetence: {
          competence: action.payload,
          loading: true,
          error: null,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.COMPETENCE_DELETE_FAILURE:
      return {
        ...state,
        activeCompetence: {
          competence: null,
          loading: false,
          error: action.payload,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.COMPETENCE_DELETE_SUCCESS:
      return {
        ...state,
        competenciesList: {
          ...state.competenciesList,
          competencies: state.competenciesList.competencies.filter(
            competence => competence.id !== action.payload.id
          ),
          error: null,
          loading: false,
        },
        activeCompetence: {
          competence: action.payload,
          loading: false,
          error: null,
          mode: MODE.DELETE,
        },
      };
    //==================================  COMPETENCE DELETE END  ==================================//
    //================================== COMPETENCE FETCH START ==================================//
    case ACTIONS.COMPETENCE_FETCH_START:
      return {
        ...state,
        activeCompetence: {
          competence: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.COMPETENCE_FETCH_FAILURE:
      return {
        ...state,
        activeCompetence: {
          competence: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.COMPETENCE_FETCH_SUCCESS:
      return {
        ...state,
        activeCompetence: {
          competence: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  COMPETENCE FETCH END  ==================================//
    //================================== COMPETENCIES FETCH START ==================================//
    case ACTIONS.COMPETENCIES_FETCH_START:
      return {
        ...state,
        competenciesList: {
          competencies: [],
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case ACTIONS.COMPETENCIES_FETCH_SUCCESS:
      return {
        ...state,
        competenciesList: {
          ...state.competenciesList,
          competencies: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case ACTIONS.COMPETENCIES_FETCH_FAILURE:
      return {
        ...state,
        competenciesList: {
          ...state.competenciesList,
          competencies: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case ACTIONS.COMPETENCIES_FETCH_RESET:
      return {
        ...state,
        competenciesList: {
          competencies: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  COMPETENCIES FETCH END  ==================================//
    default:
      return state;
  }
};
