export const ACTIONS = {
  HANDLE_UPLOAD_IN_PROGRESS: 'handleUploadInProgress',
  HANDLE_SUCCESS_GET_UPLOAD_URL: 'handleSuccessGetUploadUrl',
  HANDLE_SUCCESS_UPLOAD_URL: 'handleSuccessUploadUrl',
  TOGGLE_CONFIRM_MODAL: 'toggleConfirmModal',
};

export const UPLOAD_TYPES = {
  COMPANY_LOGO: 'company_logo',
  USER_PROFILE_IMAGE: 'user_profile_picture',
  BICYCLE_IMAGE: 'bicycle',
  SERVICE_IMAGE: 'service_image',
  SERVICE_DETAILS_IMAGE: 'service_detail_image',
};

export const TOAST_KEY_WORD = 'TOAST';

export const TOAST_TYPES = {
  info: 'info',
  success: 'success',
  warning: 'warn',
  error: 'error',
};

export const IMAGE_UPLOAD_ALLOWED_TYPES = ['image/jpg', 'image/jpeg', 'image/png'];
