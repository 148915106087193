export const ACTIONS = {
  EXPERT_FETCH_START: 'EXPERT_FETCH_START',
  EXPERT_FETCH_SUCCESS: 'EXPERT_FETCH_SUCCESS',
  EXPERT_FETCH_FAILURE: 'EXPERT_FETCH_FAILURE',
  EXPERT_FETCH_RESET: 'EXPERT_FETCH_RESET',

  EXPERT_CANCEL_START: 'EXPERT_CANCEL_START',
  EXPERT_CANCEL_SUCCESS: 'EXPERT_CANCEL_SUCCESS',
  EXPERT_CANCEL_FAILURE: 'EXPERT_CANCEL_FAILURE',
};
