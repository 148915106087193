import { ACTIONS } from '../constants/languages';
import { MODE } from '../constants/mode';

const INITIAL_STATE = {
  languagesList: { languages: [], params: {}, pager: null, error: null, loading: false },
  activeLanguage: { language: null, error: null, mode: null },
};

export const languages = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.LANGUAGE_CREATE_RESET:
    case ACTIONS.LANGUAGE_UPDATE_RESET:
    case ACTIONS.LANGUAGE_DELETE_RESET:
    case ACTIONS.LANGUAGE_FETCH_RESET:
      return {
        ...state,
        activeLanguage: {
          language: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //================================== LANGUAGE CREATE START ==================================//
    case ACTIONS.LANGUAGE_CREATE_START:
      return {
        ...state,
        activeLanguage: {
          language: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.LANGUAGE_CREATE_FAILURE:
      return {
        ...state,
        activeLanguage: {
          language: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.LANGUAGE_CREATE_SUCCESS:
      return {
        ...state,
        languagesList: {
          ...state.languagesList,
          languages: state.languagesList.languages.concat(action.payload),
          error: null,
          loading: false,
        },
        activeLanguage: {
          language: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    //==================================  LANGUAGE CREATE END  ==================================//
    //================================== LANGUAGE UPDATE START ==================================//
    case ACTIONS.LANGUAGE_UPDATE_START:
      return {
        ...state,
        activeLanguage: {
          language: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.LANGUAGE_UPDATE_FAILURE:
      return {
        ...state,
        activeLanguage: {
          language: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.LANGUAGE_UPDATE_SUCCESS:
      return {
        ...state,
        languagesList: {
          ...state.languagesList,
          languages: state.languagesList.languages.map(perm => {
            if (perm.id === action.payload.id) {
              perm = action.payload;
            }
            return perm;
          }),
          error: null,
          loading: false,
        },
        activeLanguage: {
          language: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  LANGUAGE UPDATE END  ==================================//
    //================================== LANGUAGE DELETE START ==================================//
    case ACTIONS.LANGUAGE_DELETE_START:
      return {
        ...state,
        activeLanguage: {
          language: action.payload,
          loading: true,
          error: null,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.LANGUAGE_DELETE_FAILURE:
      return {
        ...state,
        activeLanguage: {
          language: null,
          loading: false,
          error: action.payload,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.LANGUAGE_DELETE_SUCCESS:
      return {
        ...state,
        languagesList: {
          ...state.languagesList,
          languages: state.languagesList.languages.filter(
            language => language.id !== action.payload.id
          ),
          error: null,
          loading: false,
        },
        activeLanguage: {
          language: action.payload,
          loading: false,
          error: null,
          mode: MODE.DELETE,
        },
      };
    //==================================  LANGUAGE DELETE END  ==================================//
    //================================== LANGUAGE FETCH START ==================================//
    case ACTIONS.LANGUAGE_FETCH_START:
      return {
        ...state,
        activeLanguage: {
          language: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.LANGUAGE_FETCH_FAILURE:
      return {
        ...state,
        activeLanguage: {
          language: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.LANGUAGE_FETCH_SUCCESS:
      return {
        ...state,
        activeLanguage: {
          language: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  LANGUAGE FETCH END  ==================================//
    //================================== LANGUAGES FETCH START ==================================//
    case ACTIONS.LANGUAGES_FETCH_START:
      return {
        ...state,
        languagesList: {
          languages: [],
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case ACTIONS.LANGUAGES_FETCH_SUCCESS:
      return {
        ...state,
        languagesList: {
          ...state.languagesList,
          languages: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case ACTIONS.LANGUAGES_FETCH_FAILURE:
      return {
        ...state,
        languagesList: {
          ...state.languagesList,
          languages: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case ACTIONS.LANGUAGES_FETCH_RESET:
      return {
        ...state,
        languagesList: {
          languages: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  LANGUAGES FETCH END  ==================================//
    default:
      return state;
  }
};
