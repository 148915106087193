import React from 'react';
import { Route } from 'react-router-dom';

import ProtectedRoute from './protected-route';

const AppRoute = route => {
  if (route.requiresAuth) {
    return <ProtectedRoute {...route} />;
  } else {
    return (
      <Route
        path={route.path}
        render={props => (
          // pass the sub-routes down to keep nesting
          <route.component {...props} routes={route.routes} />
        )}
      />
    );
  }
};

export default AppRoute;
