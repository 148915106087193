import { ACTIONS } from '../constants/service_slots_unreachable';

const INITIAL_STATE = {
  serviceSlotsUnreachableList: [],
  paginator: {},
  error: null,
  loading: false,
  showServiceSlotUnreachableModal: false,
  serviceSlotUnreachable: {},
  message: '',
};

export const serviceSlotsUnreachable = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.HANDLE_LOADING_SERVICE_SLOT_UNREACHABLES:
      return { ...state, loading: action.payload };
    case ACTIONS.HANDLE_SUCCESS_GET_SERVICE_SLOT_UNREACHABLES: {
      return {
        ...state,
        serviceSlotsUnreachableList: action.payload.results,
        paginator: action.payload.paginator,
      };
    }
    case ACTIONS.HANDLE_ERROR_GET_SERVICE_SLOT_UNREACHABLES:
      return { ...state, error: action.payload };
    case ACTIONS.TOGGLE_SERVICE_SLOT_UNREACHABLE_MODAL:
      return {
        ...state,
        serviceSlotUnreachable: action.payload.serviceSlotUnreachable || {},
        showServiceSlotUnreachableModal: action.payload.show,
      };
    case ACTIONS.HANDLE_SUCCESS_ADD_SERVICE_SLOT_UNREACHABLE:
      return {
        ...state,
        serviceSlotsUnreachableList: action.payload.serviceSlotUnreachable
          ? state.serviceSlotsUnreachableList.concat(action.payload.serviceSlotUnreachable)
          : state.serviceSlotsUnreachableList,
        message: action.payload.message,
      };
    case ACTIONS.HANDLE_SUCCESS_UPDATE_SERVICE_SLOT_UNREACHABLE:
      return {
        ...state,
        serviceSlotsUnreachableList: action.payload.serviceSlotUnreachable
          ? state.serviceSlotsUnreachableList.map(item => {
              if (item.id === action.payload.serviceSlotUnreachable.id) {
                item = action.payload.serviceSlotUnreachable;
              }
              return item;
            })
          : state.serviceSlotsUnreachableList,
        message: action.payload.message,
      };
    case ACTIONS.HANDLE_ALTER_SERVICE_SLOT_UNREACHABLE_IN_PROGRESS:
      return {
        ...state,
        loading: action.payload.inProgress,
        error: action.payload.error,
      };
    case ACTIONS.HANDLE_SUCCESS_DELETE_SERVICE_SLOT_UNREACHABLE:
      return {
        ...state,
        serviceSlotsUnreachableList: action.payload,
      };
    default:
      return state;
  }
};
