import { ACTIONS } from '../constants/profile';
import AccountAPI from '../api/account';
import {
  setActionCreatorStart,
  setActionCreatorSuccess,
  setActionCreatorFailure,
} from '../actions/common';

// TODO replace {profileFetchReset, profileUpdateReset} with setActionCreatorReset

export const profileFetch = () => {
  return dispatch => {
    dispatch(setActionCreatorStart(ACTIONS.PROFILE_FETCH_START));
    return AccountAPI.getProfile()
      .then(response => {
        if (response.data) {
          if (response.data.success) {
            dispatch(setActionCreatorSuccess(response.data.data, ACTIONS.PROFILE_FETCH_SUCCESS));
          } else {
            dispatch(
              setActionCreatorFailure(new Error(response.data.error), ACTIONS.PROFILE_FETCH_FAILURE)
            );
          }
        } else {
          dispatch(
            setActionCreatorFailure(
              new Error('Unexpected server response'),
              ACTIONS.PROFILE_FETCH_FAILURE
            )
          );
        }
      })
      .catch(error => {
        dispatch(setActionCreatorFailure(error, ACTIONS.PROFILE_FETCH_FAILURE));
      });
  };
};

export const profileFetchReset = () => {
  return {
    type: ACTIONS.PROFILE_FETCH_RESET,
  };
};

export const profileUpdate = profile => {
  return dispatch => {
    dispatch(setActionCreatorStart(ACTIONS.PROFILE_UPDATE_START));
    return AccountAPI.updateProfile(profile)
      .then(response => {
        if (response.data) {
          if (response.data.success) {
            dispatch(setActionCreatorSuccess(response.data.data, ACTIONS.PROFILE_UPDATE_SUCCESS));
          } else {
            dispatch(
              setActionCreatorFailure(
                new Error(response.data.error),
                ACTIONS.PROFILE_UPDATE_FAILURE
              )
            );
          }
        } else {
          dispatch(
            setActionCreatorFailure(
              new Error('Unexpected server response'),
              ACTIONS.PROFILE_UPDATE_FAILURE
            )
          );
        }
      })
      .catch(error => {
        dispatch(setActionCreatorFailure(error, ACTIONS.PROFILE_UPDATE_FAILURE));
      });
  };
};

export const profileUpdateReset = () => {
  return {
    type: ACTIONS.PROFILE_UPDATE_RESET,
  };
};
