import { ACTIONS } from '../constants/profile';
import { MODE } from '../constants/mode';

const profileInitialState = {
  user: null,
  error: null,
  loading: false,
  updateLoading: false,
  mode: null,
};

export const profile = (state = profileInitialState, action) => {
  switch (action.type) {
    case ACTIONS.PROFILE_FETCH_START:
      return {
        ...state,
        user: null,
        error: null,
        loading: true,
        mode: MODE.VIEW,
      };
    case ACTIONS.PROFILE_FETCH_SUCCESS:
      return {
        ...state,
        user: action.payload,
        error: null,
        loading: false,
        mode: MODE.VIEW,
      };
    case ACTIONS.PROFILE_FETCH_FAILURE:
      return {
        ...state,
        user: null,
        error: action.payload,
        loading: false,
        mode: MODE.VIEW,
      };
    case ACTIONS.PROFILE_FETCH_RESET:
    case ACTIONS.PROFILE_UPDATE_RESET:
      return {
        ...state,
        user: null,
        error: null,
        loading: false,
        updateLoading: false,
        mode: null,
      };
    case ACTIONS.PROFILE_UPDATE_START:
      return {
        ...state,
        error: null,
        updateLoading: true,
      };
    case ACTIONS.PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        user: action.payload,
        error: null,
        updateLoading: false,
        mode: MODE.UPDATE,
      };
    case ACTIONS.PROFILE_UPDATE_FAILURE:
      return {
        ...state,
        user: null,
        error: action.payload,
        updateLoading: false,
        mode: MODE.UPDATE,
      };

    default:
      return state;
  }
};
