import CommonAPI from '../api/common';
import { ACTIONS } from '../constants/common';
import * as language from '../languages/en';

export const generateUploadURL = (params, file, onSuccess, props) => {
  return dispatch => {
    dispatch(handleUploadInProgress(true));
    return CommonAPI.generateUploadURL(params)
      .then(response => {
        if (response && response.data && response.data.success) {
          const url = response.data.data && response.data.data.url;
          const accessUrl = response.data.data && response.data.data.access_url;
          if (file) {
            dispatch(uploadFile(file, url, accessUrl, onSuccess, props));
          } else {
            dispatch(handleSuccessGetUploadUrl(url));
            dispatch(handleUploadInProgress(false));
          }
        }
      })
      .catch(error => {
        dispatch(handleUploadInProgress(false));
      });
  };
};

export const uploadFile = (file, url, accessUrl, onSuccess, props) => {
  return dispatch => {
    dispatch(handleUploadInProgress(true));
    return CommonAPI.uploadFile(file, url)
      .then(response => {
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess(accessUrl, props);
        }

        dispatch(handleSuccessUploadFile(accessUrl));
        dispatch(handleUploadInProgress(false));
      })
      .catch(error => {
        dispatch(handleUploadInProgress(false));
      });
  };
};

export const handleUploadInProgress = inProgress => {
  return {
    type: ACTIONS.HANDLE_UPLOAD_IN_PROGRESS,
    payload: inProgress,
  };
};

export const handleSuccessUploadFile = url => {
  return {
    type: ACTIONS.HANDLE_SUCCESS_UPLOAD_URL,
    payload: url,
  };
};

export const handleSuccessGetUploadUrl = url => {
  return {
    type: ACTIONS.HANDLE_SUCCESS_GET_UPLOAD_URL,
    payload: url,
  };
};

export const toggleConfirmModal = (show, title, message, onConfirm) => {
  return {
    type: ACTIONS.TOGGLE_CONFIRM_MODAL,
    payload: {
      show,
      title,
      message,
      onConfirm,
    },
  };
};

export const setActionCreatorStart = (actionType, actionPayload = undefined) => {
  return actionPayload
    ? {
        type: actionType,
        payload: actionPayload,
      }
    : {
        type: actionType,
      };
};

export const setActionCreatorReset = actionType => {
  return {
    type: actionType,
  };
};

export const setActionCreatorSuccess = (actionPayload, actionType) => {
  return {
    type: actionType,
    payload: actionPayload,
  };
};

export const setActionCreatorFailure = (error, actionType) => {
  let err = error;

  if (err.error) {
    err = err.error;
  }

  if (error.message) {
    err = error.message; // this is usually network error
  }

  if (error.response && error.response.data) {
    if (
      error.response.data.error &&
      error.response.data.error.message &&
      error.response.data.error.cause
    ) {
      if (
        error.response.data.error.message === 'wrong_password' ||
        error.response.data.error.message === 'user_does_not_exist'
      ) {
        err = language['APP.LOGIN.INVALID_CREDENTIALS'];
      } else {
        err = error.response.data.error.cause;
      }
    } else {
      err = error.response.data;
    }
  }

  return {
    type: actionType,
    payload: err,
  };
};
