export const ACTIONS = {
  SERVICES_FETCH_START: 'SERVICES_FETCH_START',
  SERVICES_FETCH_SUCCESS: 'SERVICES_FETCH_SUCCESS',
  SERVICES_FETCH_FAILURE: 'SERVICES_FETCH_FAILURE',
  SERVICES_FETCH_RESET: 'SERVICES_FETCH_RESET',

  SERVICE_FETCH_START: 'SERVICE_FETCH_START',
  SERVICE_FETCH_SUCCESS: 'SERVICE_FETCH_SUCCESS',
  SERVICE_FETCH_FAILURE: 'SERVICE_FETCH_FAILURE',
  SERVICE_FETCH_RESET: 'SERVICE_FETCH_RESET',

  SERVICE_CREATE_START: 'SERVICE_CREATE_START',
  SERVICE_CREATE_SUCCESS: 'SERVICE_CREATE_SUCCESS',
  SERVICE_CREATE_FAILURE: 'SERVICE_CREATE_FAILURE',
  SERVICE_CREATE_RESET: 'SERVICE_CREATE_RESET',

  SERVICE_UPDATE_START: 'SERVICE_UPDATE_START',
  SERVICE_UPDATE_SUCCESS: 'SERVICE_UPDATE_SUCCESS',
  SERVICE_UPDATE_FAILURE: 'SERVICE_UPDATE_FAILURE',
  SERVICE_UPDATE_RESET: 'SERVICE_UPDATE_RESET',

  SERVICE_DELETE_START: 'SERVICE_DELETE_START',
  SERVICE_DELETE_SUCCESS: 'SERVICE_DELETE_SUCCESS',
  SERVICE_DELETE_FAILURE: 'SERVICE_DELETE_FAILURE',
  SERVICE_DELETE_RESET: 'SERVICE_DELETE_RESET',

  SERVICE_DETAILS_FETCH_START: 'SERVICE_DETAILS_FETCH_START',
  SERVICE_DETAILS_FETCH_SUCCESS: 'SERVICE_DETAILS_FETCH_SUCCESS',
  SERVICE_DETAILS_FETCH_FAILURE: 'SERVICE_DETAILS_FETCH_FAILURE',
  SERVICE_DETAILS_FETCH_RESET: 'SERVICE_DETAILS_FETCH_RESET',
};
