import React, { Component } from 'react';

class SidebarHeader extends Component {
  render() {
    return (
      //   <div className="sidebar-header"></div>
      <div />
    );
  }
}

export default SidebarHeader;
