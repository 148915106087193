import React, { Component } from 'react';

class SidebarFooter extends Component {
  render() {
    return (
      // <div className="sidebar-footer"></div>
      <div />
    );
  }
}

export default SidebarFooter;
