export const ACTIONS = {
  BICYCLES_FETCH_START: 'BICYCLES_FETCH_START',
  BICYCLES_FETCH_SUCCESS: 'BICYCLES_FETCH_SUCCESS',
  BICYCLES_FETCH_FAILURE: 'BICYCLES_FETCH_FAILURE',
  BICYCLES_FETCH_RESET: 'BICYCLES_FETCH_RESET',

  BICYCLE_FETCH_START: 'BICYCLE_FETCH_START',
  BICYCLE_FETCH_SUCCESS: 'BICYCLE_FETCH_SUCCESS',
  BICYCLE_FETCH_FAILURE: 'BICYCLE_FETCH_FAILURE',
  BICYCLE_FETCH_RESET: 'BICYCLE_FETCH_RESET',

  BICYCLE_CREATE_START: 'BICYCLE_CREATE_START',
  BICYCLE_CREATE_SUCCESS: 'BICYCLE_CREATE_SUCCESS',
  BICYCLE_CREATE_FAILURE: 'BICYCLE_CREATE_FAILURE',
  BICYCLE_CREATE_RESET: 'BICYCLE_CREATE_RESET',

  BICYCLE_UPDATE_START: 'BICYCLE_UPDATE_START',
  BICYCLE_UPDATE_SUCCESS: 'BICYCLE_UPDATE_SUCCESS',
  BICYCLE_UPDATE_FAILURE: 'BICYCLE_UPDATE_FAILURE',
  BICYCLE_UPDATE_RESET: 'BICYCLE_UPDATE_RESET',

  BICYCLE_DELETE_START: 'BICYCLE_DELETE_START',
  BICYCLE_DELETE_SUCCESS: 'BICYCLE_DELETE_SUCCESS',
  BICYCLE_DELETE_FAILURE: 'BICYCLE_DELETE_FAILURE',
  BICYCLE_DELETE_RESET: 'BICYCLE_DELETE_RESET',

  BICYCLE_SERVICES_FETCH_START: 'BICYCLE_SERVICES_FETCH_START',
  BICYCLE_SERVICES_FETCH_SUCCESS: 'BICYCLE_SERVICES_FETCH_SUCCESS',
  BICYCLE_SERVICES_FETCH_FAILURE: 'BICYCLE_SERVICES_FETCH_FAILURE',
  BICYCLE_SERVICES_FETCH_RESET: 'BICYCLE_SERVICES_FETCH_RESET',

  BICYCLE_IMAGES_FETCH_START: 'BICYCLE_IMAGES_FETCH_START',
  BICYCLE_IMAGES_FETCH_SUCCESS: 'BICYCLE_IMAGES_FETCH_SUCCESS',
  BICYCLE_IMAGES_FETCH_FAILURE: 'BICYCLE_IMAGES_FETCH_FAILURE',
  BICYCLE_IMAGES_FETCH_RESET: 'BICYCLE_IMAGES_FETCH_RESET',

  BICYCLE_DEFECT_IMAGE_CREATE_START: 'BICYCLE_DEFECT_IMAGE_CREATE_START',
  BICYCLE_DEFECT_IMAGE_CREATE_SUCCESS: 'BICYCLE_DEFECT_IMAGE_CREATE_SUCCESS',
  BICYCLE_DEFECT_IMAGE_CREATE_FAILURE: 'BICYCLE_DEFECT_IMAGE_CREATE_FAILURE',
  BICYCLE_DEFECT_IMAGE_CREATE_RESET: ' BICYCLE_DEFECT_IMAGE_CREATE_RESET',

  BICYCLE_DEFECT_IMAGE_FETCH_START: 'BICYCLE_DEFECT_IMAGE_FETCH_START',
  BICYCLE_DEFECT_IMAGE_FETCH_SUCCESS: 'BICYCLE_DEFECT_IMAGE_FETCH_SUCCESS',
  BICYCLE_DEFECT_IMAGE_FETCH_FAILURE: 'BICYCLE_DEFECT_IMAGE_FETCH_FAILURE',
  BICYCLE_DEFECT_IMAGE_FETCH_RESET: 'BICYCLE_DEFECT_IMAGE_FETCH_RESET',

  BICYCLE_DEFECT_IMAGES_FETCH_START: 'BICYCLE_DEFECT_IMAGES_FETCH_START',
  BICYCLE_DEFECT_IMAGES_FETCH_SUCCESS: 'BICYCLE_DEFECT_IMAGES_FETCH_SUCCESS',
  BICYCLE_DEFECT_IMAGES_FETCH_FAILURE: 'BICYCLE_DEFECT_IMAGES_FETCH_FAILURE',
  BICYCLE_DEFECT_IMAGES_FETCH_RESET: 'BICYCLE_DEFECT_IMAGES_FETCH_RESET',

  BICYCLE_DEFECT_IMAGE_UPDATE_START: 'BICYCLE_DEFECT_IMAGE_UPDATE_START',
  BICYCLE_DEFECT_IMAGE_UPDATE_SUCCESS: 'BICYCLE_DEFECT_IMAGE_UPDATE_SUCCESS',
  BICYCLE_DEFECT_IMAGE_UPDATE_FAILURE: 'BICYCLE_DEFECT_IMAGE_UPDATE_FAILURE',
  BICYCLE_DEFECT_IMAGE_UPDATE_RESET: 'BICYCLE_DEFECT_IMAGE_UPDATE_RESET',

  BICYCLE_DEFECT_IMAGE_DELETE_START: 'BICYCLE_DEFECT_IMAGE_DELETE_START',
  BICYCLE_DEFECT_IMAGE_DELETE_SUCCESS: ' BICYCLE_DEFECT_IMAGE_DELETE_SUCCESS',
  BICYCLE_DEFECT_IMAGE_DELETE_FAILURE: 'BICYCLE_DEFECT_IMAGE_DELETE_FAILURE',
  BICYCLE_DEFECT_IMAGE_DELETE_RESET: 'BICYCLE_DEFECT_IMAGE_DELETE_RESET',
};
