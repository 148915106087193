import { ACTIONS } from '../constants/available-bike-buddy';

const INITIAL_STATE = {
  availableBikeBuddyList: [],
  error: null,
  loading: false,
};

export const availableBikeBuddy = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.FETCHING_BIKE_BUDDY:
      return { ...state, loading: action.payload };
    case ACTIONS.HANDLE_SUCCESS_GET_BIKE_BUDDY: {
      return {
        ...state,
        availableBikeBuddyList: action.payload,
        loading: false,
      };
    }
    default:
      return state;
  }
};
