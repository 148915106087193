export const ACTIONS = {
  LANGUAGES_FETCH_START: 'LANGUAGES_FETCH_START',
  LANGUAGES_FETCH_SUCCESS: 'LANGUAGES_FETCH_SUCCESS',
  LANGUAGES_FETCH_FAILURE: 'LANGUAGES_FETCH_FAILURE',
  LANGUAGES_FETCH_RESET: 'LANGUAGES_FETCH_RESET',

  LANGUAGE_FETCH_START: 'LANGUAGE_FETCH_START',
  LANGUAGE_FETCH_SUCCESS: 'LANGUAGE_FETCH_SUCCESS',
  LANGUAGE_FETCH_FAILURE: 'LANGUAGE_FETCH_FAILURE',
  LANGUAGE_FETCH_RESET: 'LANGUAGE_FETCH_RESET',

  LANGUAGE_CREATE_START: 'LANGUAGE_CREATE_START',
  LANGUAGE_CREATE_SUCCESS: 'LANGUAGE_CREATE_SUCCESS',
  LANGUAGE_CREATE_FAILURE: 'LANGUAGE_CREATE_FAILURE',
  LANGUAGE_CREATE_RESET: 'LANGUAGE_CREATE_RESET',

  LANGUAGE_UPDATE_START: 'LANGUAGE_UPDATE_START',
  LANGUAGE_UPDATE_SUCCESS: 'LANGUAGE_UPDATE_SUCCESS',
  LANGUAGE_UPDATE_FAILURE: 'LANGUAGE_UPDATE_FAILURE',
  LANGUAGE_UPDATE_RESET: 'LANGUAGE_UPDATE_RESET',

  LANGUAGE_DELETE_START: 'LANGUAGE_DELETE_START',
  LANGUAGE_DELETE_SUCCESS: 'LANGUAGE_DELETE_SUCCESS',
  LANGUAGE_DELETE_FAILURE: 'LANGUAGE_DELETE_FAILURE',
  LANGUAGE_DELETE_RESET: 'LANGUAGE_DELETE_RESET',
};
