export const ACTIONS = {
  PROFILE_FETCH_START: 'PROFILE_FETCH_START',
  PROFILE_FETCH_SUCCESS: 'PROFILE_FETCH',
  PROFILE_FETCH_FAILURE: 'PROFILE_FETCH_FAILURE',
  PROFILE_FETCH_RESET: 'PROFILE_CLEAR',

  PROFILE_UPDATE_START: 'PROFILE_UPDATE_START',
  PROFILE_UPDATE_SUCCESS: 'PROFILE_UPDATE_SUCCESS',
  PROFILE_UPDATE_FAILURE: 'PROFILE_UPDATE_FAILURE',
  PROFILE_UPDATE_RESET: 'PROFILE_UPDATE_RESET',
};
