import { ACTIONS } from '../constants/subscriptions';

const INITIAL_STATE = {
  subscriptionsList: [],
  subscriptionPlanList: [],
  paginator: {},
  error: null,
  loading: false,
};

export const subscriptions = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.FETCHING_SUBSCRIPTIONS:
      return { ...state, loading: action.payload };
    case ACTIONS.HANDLE_SUCCESS_GET_SUBSCRIPTIONS: {
      return {
        ...state,
        subscriptionsList: action.payload,
        loading: false,
      };
    }
    case ACTIONS.FETCHING_SUBSCRIPTION_PLANS:
      return { ...state, loading: action.payload };
    case ACTIONS.HANDLE_SUCCESS_GET_SUBSCRIPTION_PLANS: {
      return {
        ...state,
        subscriptionPlanList: action.payload,
        loading: false,
      };
    }
    default:
      return state;
  }
};
