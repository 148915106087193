import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Badge, Nav, NavItem, NavLink as RsNavLink } from 'reactstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import SidebarFooter from './sidebar-footer';
import SidebarForm from './sidebar-form';
import SidebarHeader from './sidebar-header';
import SidebarMinimizer from './sidebar-minimizer';

class Sidebar extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  };

  static propTypes = {
    permissions: PropTypes.array,
  };

  handleClick(e) {
    e.preventDefault();
    e.target.parentElement.classList.toggle('open');
  }

  activeRoute(routeName, props) {
    return props.location.pathname.indexOf(routeName) > -1
      ? 'nav-item nav-dropdown open'
      : 'nav-item nav-dropdown';
  }

  checkActive = (match, location) => {
    if (match && match.isExact) return true;
    if (!location || !match) return false;

    const { pathname } = location;

    return match.url !== '' && pathname.indexOf(match.url) !== -1;
  };

  render() {
    const props = this.props;
    const activeRoute = this.activeRoute;
    const handleClick = this.handleClick;

    // badge addon to NavItem
    const badge = badge => {
      if (badge) {
        const classes = classNames(badge.class);
        return (
          <Badge className={classes} color={badge.variant}>
            {badge.text}
          </Badge>
        );
      }
    };

    // simple wrapper for nav-title item
    const wrapper = item => {
      const trans = this.context.t(item.name);
      return item.wrapper && item.wrapper.element
        ? React.createElement(item.wrapper.element, item.wrapper.attributes, trans)
        : trans;
    };

    // nav list section title
    const title = (title, key) => {
      const classes = classNames('nav-title', title.class);
      return (
        <li key={key} className={classes}>
          {wrapper(title)}{' '}
        </li>
      );
    };

    // nav list divider
    const divider = (divider, key) => {
      const classes = classNames('divider', divider.class);
      return <li key={key} className={classes} />;
    };

    // nav label with nav link
    const navLabel = (item, key) => {
      const classes = {
        item: classNames('hidden-cn', item.class),
        link: classNames('nav-label', item.class ? item.class : ''),
        icon: classNames(
          !item.icon ? 'fa fa-circle' : item.icon,
          item.label.variant ? `text-${item.label.variant}` : '',
          item.label.class ? item.label.class : ''
        ),
      };
      return navLink(item, key, classes);
    };

    // nav item with nav link
    const navItem = (item, key) => {
      const classes = {
        item: classNames(item.class),
        link: classNames('nav-link', item.variant ? `nav-link-${item.variant}` : ''),
        icon: classNames(item.icon),
      };
      return navLink(item, key, classes);
    };

    const onLinkClick = e => {
      document.body.classList.toggle('sidebar-mobile-show');
    };

    // nav link
    const navLink = (item, key, classes) => {
      const url = item.path ? item.path : '';
      return (
        <NavItem key={key} className={classes.item}>
          {isExternal(url) ? (
            <RsNavLink href={url} className={classes.link}>
              <i className={classes.icon} />
              {this.context.t(item.name)}
              {badge(item.badge)}
            </RsNavLink>
          ) : (
            <NavLink
              to={url}
              isActive={this.checkActive}
              className={classes.link}
              activeClassName="active"
              onClick={onLinkClick}
            >
              <i className={classes.icon} />
              {this.context.t(item.name)}
              {badge(item.badge)}
            </NavLink>
          )}
        </NavItem>
      );
    };

    // nav dropdown
    const navDropdown = (item, key) => {
      return (
        <li key={key} className={activeRoute(item.url, props)}>
          <button className="nav-link nav-dropdown-toggle" onClick={handleClick.bind(this)}>
            <i className={item.icon} />
            {this.context.t(item.name)}
          </button>
          <ul className="nav-dropdown-items">{navList(item.children)}</ul>
        </li>
      );
    };

    // nav type
    const navType = (item, idx) =>
      item.title
        ? title(item, idx)
        : item.divider
        ? divider(item, idx)
        : item.label
        ? navLabel(item, idx)
        : item.children
        ? navDropdown(item, idx)
        : navItem(item, idx);

    // nav list
    const navList = items => {
      return items.map((item, index) => {
        if (item.permissions === undefined || item.permissions.length === 0) {
          return navType(item, index);
        }

        const scope = item.permissions.filter(item => {
          return this.props.permissions.indexOf(item) > -1;
        });

        if (scope.length > 0) {
          return navType(item, index);
        }
        return '';
      });
    };

    const isExternal = url => {
      const link = url ? url.substring(0, 4) : '';
      return link === 'http';
    };

    // sidebar-nav root
    return (
      <div className="sidebar">
        <SidebarHeader />
        <SidebarForm />
        <nav className="sidebar-nav">
          <Nav>{navList(this.props.nav)}</Nav>
        </nav>
        <SidebarFooter />
        <SidebarMinimizer />
      </div>
    );
  }
}

export default Sidebar;
