import { ACTIONS } from '../constants/bicycle-types';
import { MODE } from '../constants/mode';

const INITIAL_STATE = {
  bicycleTypesList: { bicycleTypes: [], params: {}, pager: null, error: null, loading: false },
  activeBicycleType: { bicycleType: null, error: null, mode: null },
};

export const bicycleTypes = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.BICYCLE_TYPE_CREATE_RESET:
    case ACTIONS.BICYCLE_TYPE_UPDATE_RESET:
    case ACTIONS.BICYCLE_TYPE_DELETE_RESET:
    case ACTIONS.BICYCLE_TYPE_FETCH_RESET:
      return {
        ...state,
        activeBicycleType: {
          bicycleType: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //================================== BICYCLE_TYPE CREATE START ==================================//
    case ACTIONS.BICYCLE_TYPE_CREATE_START:
      return {
        ...state,
        activeBicycleType: {
          bicycleType: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.BICYCLE_TYPE_CREATE_FAILURE:
      return {
        ...state,
        activeBicycleType: {
          bicycleType: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.BICYCLE_TYPE_CREATE_SUCCESS:
      return {
        ...state,
        bicycleTypesList: {
          ...state.bicycleTypesList,
          bicycleTypes: state.bicycleTypesList.bicycleTypes.concat(action.payload),
          error: null,
          loading: false,
        },
        activeBicycleType: {
          bicycleType: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    //==================================  BICYCLE_TYPE CREATE END  ==================================//
    //================================== BICYCLE_TYPE UPDATE START ==================================//
    case ACTIONS.BICYCLE_TYPE_UPDATE_START:
      return {
        ...state,
        activeBicycleType: {
          bicycleType: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.BICYCLE_TYPE_UPDATE_FAILURE:
      return {
        ...state,
        activeBicycleType: {
          bicycleType: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.BICYCLE_TYPE_UPDATE_SUCCESS:
      return {
        ...state,
        bicycleTypesList: {
          ...state.bicycleTypesList,
          bicycleTypes: state.bicycleTypesList.bicycleTypes.map(perm => {
            if (perm.id === action.payload.id) {
              perm = action.payload;
            }
            return perm;
          }),
          error: null,
          loading: false,
        },
        activeBicycleType: {
          bicycleType: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  BICYCLE_TYPE UPDATE END  ==================================//
    //================================== BICYCLE_TYPE DELETE START ==================================//
    case ACTIONS.BICYCLE_TYPE_DELETE_START:
      return {
        ...state,
        activeBicycleType: {
          bicycleType: action.payload,
          loading: true,
          error: null,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.BICYCLE_TYPE_DELETE_FAILURE:
      return {
        ...state,
        activeBicycleType: {
          bicycleType: null,
          loading: false,
          error: action.payload,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.BICYCLE_TYPE_DELETE_SUCCESS:
      return {
        ...state,
        bicycleTypesList: {
          ...state.bicycleTypesList,
          bicycleTypes: state.bicycleTypesList.bicycleTypes.filter(
            bicycleType => bicycleType.id !== action.payload.id
          ),
          error: null,
          loading: false,
        },
        activeBicycleType: {
          bicycleType: action.payload,
          loading: false,
          error: null,
          mode: MODE.DELETE,
        },
      };
    //==================================  BICYCLE_TYPE DELETE END  ==================================//
    //================================== BICYCLE_TYPE FETCH START ==================================//
    case ACTIONS.BICYCLE_TYPE_FETCH_START:
      return {
        ...state,
        activeBicycleType: {
          bicycleType: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.BICYCLE_TYPE_FETCH_FAILURE:
      return {
        ...state,
        activeBicycleType: {
          bicycleType: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.BICYCLE_TYPE_FETCH_SUCCESS:
      return {
        ...state,
        activeBicycleType: {
          bicycleType: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  BICYCLE_TYPE FETCH END  ==================================//
    //================================== BICYCLE_TYPES FETCH START ==================================//
    case ACTIONS.BICYCLE_TYPES_FETCH_START:
      return {
        ...state,
        bicycleTypesList: {
          bicycleTypes: [],
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case ACTIONS.BICYCLE_TYPES_FETCH_SUCCESS:
      return {
        ...state,
        bicycleTypesList: {
          ...state.bicycleTypesList,
          bicycleTypes: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case ACTIONS.BICYCLE_TYPES_FETCH_FAILURE:
      return {
        ...state,
        bicycleTypesList: {
          ...state.bicycleTypesList,
          bicycleTypes: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case ACTIONS.BICYCLE_TYPES_FETCH_RESET:
      return {
        ...state,
        bicycleTypesList: {
          bicycleTypes: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  BICYCLE_TYPES FETCH END  ==================================//
    default:
      return state;
  }
};
