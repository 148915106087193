import axios from 'axios';

class AuthAPI {
  static googleLogin = tokenData => {
    return axios({
      method: 'POST',
      url: `auth/google`,
      data: tokenData,
    });
  };
}

export default AuthAPI;
