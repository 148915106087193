import { ACTIONS } from '../constants/bicycles';
import { MODE } from '../constants/mode';

const INITIAL_STATE = {
  bicyclesList: { bicycles: [], params: {}, pager: null, error: null, loading: false },
  activeBicycle: { bicycle: null, error: null, mode: null },
  bicycleServices: { services: [], params: {}, pager: null, error: null, loading: false },
  bicycleImages: { images: [], params: {}, pager: null, error: null, loading: false },
  activeDefectImage: { image: null, error: null, mode: null, loading: false },
  defectImages: { images: [], params: {}, pager: null, error: null, loading: false },
};

export const bicycles = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.BICYCLE_CREATE_RESET:
    case ACTIONS.BICYCLE_UPDATE_RESET:
    case ACTIONS.BICYCLE_DELETE_RESET:
    case ACTIONS.BICYCLE_FETCH_RESET:
      return {
        ...state,
        activeBicycle: {
          bicycle: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //================================== BICYCLE CREATE START ==================================//
    case ACTIONS.BICYCLE_CREATE_START:
      return {
        ...state,
        activeBicycle: {
          bicycle: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.BICYCLE_CREATE_FAILURE:
      return {
        ...state,
        activeBicycle: {
          bicycle: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.BICYCLE_CREATE_SUCCESS:
      return {
        ...state,
        bicyclesList: {
          ...state.bicyclesList,
          bicycles: state.bicyclesList.bicycles.concat(action.payload),
          error: null,
          loading: false,
        },
        activeBicycle: {
          bicycle: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    //==================================  BICYCLE CREATE END  ==================================//
    //================================== BICYCLE UPDATE START ==================================//
    case ACTIONS.BICYCLE_UPDATE_START:
      return {
        ...state,
        activeBicycle: {
          bicycle: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.BICYCLE_UPDATE_FAILURE:
      return {
        ...state,
        activeBicycle: {
          bicycle: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
        bicyclesList: {
          error: action.payload,
        },
      };
    case ACTIONS.BICYCLE_UPDATE_SUCCESS:
      return {
        ...state,
        bicyclesList: {
          ...state.bicyclesList,
          bicycles: state.bicyclesList.bicycles.map(perm => {
            if (perm.id === action.payload.id) {
              perm = action.payload;
            }
            return perm;
          }),
          error: null,
          loading: false,
        },
        activeBicycle: {
          bicycle: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  BICYCLE UPDATE END  ==================================//
    //================================== BICYCLE DELETE START ==================================//
    case ACTIONS.BICYCLE_DELETE_START:
      return {
        ...state,
        activeBicycle: {
          bicycle: action.payload,
          loading: true,
          error: null,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.BICYCLE_DELETE_FAILURE:
      return {
        ...state,
        activeBicycle: {
          bicycle: null,
          loading: false,
          error: action.payload,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.BICYCLE_DELETE_SUCCESS:
      return {
        ...state,
        bicyclesList: {
          ...state.bicyclesList,
          bicycles: state.bicyclesList.bicycles.filter(bicycle => bicycle.id !== action.payload.id),
          error: null,
          loading: false,
        },
        activeBicycle: {
          bicycle: action.payload,
          loading: false,
          error: null,
          mode: MODE.DELETE,
        },
      };
    //==================================  BICYCLE DELETE END  ==================================//
    //================================== BICYCLE FETCH START ==================================//
    case ACTIONS.BICYCLE_FETCH_START:
      return {
        ...state,
        activeBicycle: {
          bicycle: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.BICYCLE_FETCH_FAILURE:
      return {
        ...state,
        activeBicycle: {
          bicycle: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.BICYCLE_FETCH_SUCCESS:
      return {
        ...state,
        activeBicycle: {
          bicycle: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  BICYCLE FETCH END  ==================================//
    //================================== BICYCLES FETCH START ==================================//
    case ACTIONS.BICYCLES_FETCH_START:
      return {
        ...state,
        bicyclesList: {
          bicycles: [],
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case ACTIONS.BICYCLES_FETCH_SUCCESS:
      return {
        ...state,
        bicyclesList: {
          ...state.bicyclesList,
          bicycles: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case ACTIONS.BICYCLES_FETCH_FAILURE:
      return {
        ...state,
        bicyclesList: {
          ...state.bicyclesList,
          bicycles: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case ACTIONS.BICYCLES_FETCH_RESET:
      return {
        ...state,
        bicyclesList: {
          bicycles: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  BICYCLES FETCH END  ==================================//
    //===========================  BICYCLE SERVICES FETCH START  ==================================//
    case ACTIONS.BICYCLE_SERVICES_FETCH_START:
      return {
        ...state,
        bicycleServices: {
          services: [],
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case ACTIONS.BICYCLE_SERVICES_FETCH_SUCCESS:
      return {
        ...state,
        bicycleServices: {
          ...state.bicycleServices,
          services: action.payload.results,
          pager: action.payload.paginator,
          loading: false,
          error: null,
        },
      };
    case ACTIONS.BICYCLE_SERVICES_FETCH_FAILURE:
      return {
        ...state,
        bicycleServices: {
          ...state.bicycleServices,
          services: [],
          pager: null,
          loading: false,
          error: action.payload,
        },
      };
    case ACTIONS.BICYCLE_SERVICES_FETCH_RESET:
      return {
        ...state,
        bicycleServices: {
          services: [],
          loading: false,
          error: false,
        },
      };
    //===========================   BICYCLE SERVICES FETCH END   ==================================//
    //===========================  BICYCLE IMAGES FETCH START  ==================================//
    case ACTIONS.BICYCLE_IMAGES_FETCH_START:
      return {
        ...state,
        bicycleImages: {
          images: [],
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case ACTIONS.BICYCLE_IMAGES_FETCH_SUCCESS:
      return {
        ...state,
        bicycleImages: {
          ...state.bicycleImages,
          images: action.payload.results,
          pager: action.payload.paginator,
          loading: false,
          error: null,
        },
      };
    case ACTIONS.BICYCLE_IMAGES_FETCH_FAILURE:
      return {
        ...state,
        bicycleImages: {
          ...state.bicycleImages,
          images: [],
          pager: null,
          loading: false,
          error: action.payload,
        },
      };
    case ACTIONS.BICYCLE_IMAGES_FETCH_RESET:
      return {
        ...state,
        bicycleImages: {
          IMAGES: [],
          loading: false,
          error: false,
        },
      };
    //===========================   BICYCLE IMAGES FETCH END   ==================================//
    //===========================  BICYCLE DEFECT IMAGE CREATE START  ==================================//
    case ACTIONS.BICYCLE_DEFECT_IMAGE_CREATE_START:
      return {
        ...state,
        activeDefectImage: {
          image: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.BICYCLE_DEFECT_IMAGE_CREATE_SUCCESS:
      return {
        ...state,
        activeDefectImage: {
          image: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
        defectImages: {
          ...state.defectImages,
          images: state.defectImages.images.concat(action.payload),
          error: null,
          loading: false,
        },
      };
    case ACTIONS.BICYCLE_DEFECT_IMAGE_CREATE_FAILURE:
      return {
        ...state,
        activeDefectImage: {
          image: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.BICYCLE_DEFECT_IMAGE_CREATE_RESET:
      return {
        ...state,
        activeDefectImage: {
          image: null,
          loading: false,
          error: null,
          mode: null,
        },
      };
    //===========================   BICYCLE DEFECT IMAGE CREATE END   ==================================//
    // //===========================  BICYCLE DEFECT IMAGE FETCH START  ==================================//
    case ACTIONS.BICYCLE_DEFECT_IMAGE_FETCH_START:
      return {
        ...state,
        activeDefectImage: {
          image: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.BICYCLE_DEFECT_IMAGE_FETCH_SUCCESS:
      return {
        ...state,
        activeDefectImage: {
          image: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.BICYCLE_DEFECT_IMAGE_FETCH_FAILURE:
      return {
        ...state,
        activeDefectImage: {
          image: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.BICYCLE_DEFECT_IMAGE_FETCH_RESET:
      return {
        ...state,
        activeDefectImage: {
          image: null,
          loading: false,
          error: null,
          mode: null,
        },
      };
    // //===========================   BICYCLE DEFECT IMAGE FETCH END ==================================//
    //===========================  BICYCLE DEFECT IMAGES FETCH START  ==================================//
    case ACTIONS.BICYCLE_DEFECT_IMAGES_FETCH_START:
      return {
        ...state,
        defectImages: {
          images: [],
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case ACTIONS.BICYCLE_DEFECT_IMAGES_FETCH_SUCCESS:
      return {
        ...state,
        defectImages: {
          ...state.defectImages,
          images: action.payload.results,
          pager: action.payload.paginator,
          loading: false,
          error: null,
        },
      };
    case ACTIONS.BICYCLE_DEFECT_IMAGES_FETCH_FAILURE:
      return {
        ...state,
        defectImages: {
          ...state.defectImages,
          images: [],
          pager: null,
          loading: false,
          error: action.payload,
        },
      };
    case ACTIONS.BICYCLE_DEFECT_IMAGES_FETCH_RESET:
      return {
        ...state,
        defectImages: {
          images: [],
          pager: null,
          params: null,
          loading: false,
          error: false,
        },
      };
    //===========================   BICYCLE DEFECT IMAGES FETCH END   ==================================//
    //================================== BICYCLE DEFECT IMAGE UPDATE START ==================================//
    case ACTIONS.BICYCLE_DEFECT_IMAGE_UPDATE_START:
      return {
        ...state,
        activeDefectImage: {
          image: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.BICYCLE_DEFECT_IMAGE_UPDATE_FAILURE:
      return {
        ...state,
        activeDefectImage: {
          image: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.BICYCLE_DEFECT_IMAGE_UPDATE_SUCCESS:
      return {
        ...state,
        defectImages: {
          ...state.defectImages,
          images: state.defectImages.images.map(image => {
            if (image.id === action.payload.id) {
              image = action.payload;
            }
            return image;
          }),
          error: null,
          loading: false,
        },
        activeDefectImage: {
          image: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.BICYCLE_DEFECT_IMAGE_UPDATE_RESET:
      return {
        ...state,
        activeDefectImage: {
          image: null,
          loading: false,
          error: null,
          mode: null,
        },
      };
    //==================================  BICYCLE DEFECT IMAGE UPDATE END  ==================================//
    //================================== BICYCLE DEFECT IMAGE DELETE START ==================================//
    case ACTIONS.BICYCLE_DEFECT_IMAGE_DELETE_START:
      return {
        ...state,
        activeDefectImage: {
          image: action.payload,
          loading: true,
          error: null,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.BICYCLE_DEFECT_IMAGE_DELETE_FAILURE:
      return {
        ...state,
        activeDefectImage: {
          image: null,
          loading: false,
          error: action.payload,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.BICYCLE_DEFECT_IMAGE_DELETE_SUCCESS:
      return {
        ...state,
        defectImages: {
          ...state.defectImages,
          images: state.defectImages.images.filter(image => image.id !== action.payload.id),
          error: null,
          loading: false,
        },
        activeDefectImage: {
          image: action.payload,
          loading: false,
          error: null,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.BICYCLE_DEFECT_IMAGE_DELETE_RESET:
      return {
        ...state,
        activeDefectImage: {
          image: null,
          loading: false,
          error: null,
          mode: null,
        },
      };
    //==================================  BICYCLE_TYPE DELETE END  ==================================//
    default:
      return state;
  }
};
