export const ACTIONS = {
  HANDLE_SUCCESS_GET_SERVICE_SLOTS: 'handleSuccessGetServiceSlots',
  HANDLE_LOADING_SERVICE_SLOTS: 'handleLoadingServiceSlots',
  HANDLE_ERROR_GET_SERVICE_SLOTS: 'handleErrorGetServiceSlots',
  TOGGLE_SERVICE_SLOT_MODAL: 'toggleServiceSlotModal',
  HANDLE_ALTER_SERVICE_SLOT_IN_PROGRESS: 'handleAlterServiceSlotInProgress',
  HANDLE_SUCCESS_ADD_SERVICE_SLOT: 'handleSuccessAddServiceSlot',
  HANDLE_SUCCESS_UPDATE_SERVICE_SLOT: 'handleSuccessUpdateServiceSlot',
  HANDLE_SUCCESS_DELETE_SERVICE_SLOT: 'handleSuccessDeleteServiceSlot',
};
