import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';

import imgAvatar from '../../img/avatars/default.png';

class UserDropdown extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  };

  static propTypes = {
    user: PropTypes.object,
    onLogoutClick: PropTypes.func.isRequired,
    onProfileClick: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
    };
  }

  toggle() {
    this.setState({
      ...this.state,
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  getFullName() {
    return this.props.user ? this.props.user.first_name + ' ' + this.props.user.last_name : '';
  }

  getAvatar() {
    return this.props.user && this.props.user.profile_image !== null
      ? this.props.user.profile_image
      : imgAvatar;
  }

  render() {
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} nav>
        <DropdownToggle nav>
          <span>{this.getFullName()}</span>
          <img src={this.getAvatar()} className="img-avatar bg-white" alt={this.getFullName()} />
        </DropdownToggle>
        <DropdownMenu right className="dropdown-menu-lg">
          <DropdownItem header tag="div" className="text-center">
            <strong>{this.context.t('APP.ACCOUNT')}</strong>
          </DropdownItem>
          <DropdownItem onClick={this.props.onProfileClick}>
            <i className="fa fa-user" /> {this.context.t('APP.PROFILE')}
          </DropdownItem>
          <DropdownItem onClick={this.props.onLogoutClick}>
            <i className="fa fa-lock" /> {this.context.t('APP.LOGOUT')}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default UserDropdown;
