export const ACTIONS = {
  GET_OPERATIONAL_RANGES_IN_PROGRESS: 'getOperationalRangesInProgress',
  HANDLE_SUCCESS_GET_OPERATIONAL_RANGES: 'handleSuccessGetOperationalRanges',
  HANDLE_ERROR_GET_OPERATIONAL_RANGES: 'handleErrorGetOperationalRanges',
  TOGGLE_OPERATIONAL_RANGE_MODAL: 'toggleOperationalRangeModal',
  HANDLE_SUCCESS_ADD_OPERATIONAL_RANGE: 'handleSuccessAddOperationalRange',
  HANDLE_SUCCESS_UPDATE_OPERATIONAL_RANGE: 'handleSuccessUpdateOperationalRange',
  HANDLE_ALTERING_OPERATIONAL_RANGE_IN_PROGRESS: 'handleAlteringOperationalRangeInProgress',
  HANDLE_SUCCESS_DELETE_OPERATIONAL_RANGE: 'handleSuccessDeleteOperationalRange',
  HANDLE_SUCCESS_GET_OPERATIONAL_RANGE: 'handleSuccessGetOperationalRange',
};
