import { ACTIONS } from '../constants/operational-ranges';

const INITIAL_STATE = {
  fetchingOperationalRanges: false,
  operationalRangesList: [],
  error: null,
  paginator: {},
  showOperationalRangeModal: false,
  operationalRange: {},
  message: '',
  alterOperationalRangesInProgress: false,
  errorMessage: '',
  fetchedOperationalRange: {},
};

export const operationalRanges = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //=================================  GET OPERATIONAL RANGES LOADING  ==================================//
    case ACTIONS.GET_OPERATIONAL_RANGES_IN_PROGRESS:
      return { ...state, fetchingOperationalRanges: action.payload };
    //=================================  GET OPERATIONAL RANGES ERROR  ==================================//
    case ACTIONS.HANDLE_ERROR_GET_OPERATIONAL_RANGES:
      return { ...state, error: action.payload };
    //=================================  GET OPERATIONAL RANGES  ==================================//
    case ACTIONS.HANDLE_SUCCESS_GET_OPERATIONAL_RANGES:
      return {
        ...state,
        operationalRangesList: action.payload.results,
        paginator: action.payload.paginator,
      };
    //=================================  TOGGLE MODAL OPERATIONAL RANGE  ==================================//
    case ACTIONS.TOGGLE_OPERATIONAL_RANGE_MODAL:
      return {
        ...state,
        showOperationalRangeModal: action.payload.show,
        operationalRange: action.payload.operationalRange || {},
        message: '',
      };
    //=================================  ADD OPERATIONAL RANGE  ==================================//
    case ACTIONS.HANDLE_SUCCESS_ADD_OPERATIONAL_RANGE:
      return {
        ...state,
        operationalRange: action.payload.operationalRange || {},
        operationalRangesList: action.payload.operationalRange
          ? state.operationalRangesList.concat(action.payload.operationalRange)
          : state.operationalRangesList,
        message: action.payload.message,
      };
    //=================================  UPDATE OPERATIONAL RANGE  ==================================//
    case ACTIONS.HANDLE_SUCCESS_UPDATE_OPERATIONAL_RANGE:
      return {
        ...state,
        operationalRangesList: action.payload.operationalRange
          ? state.operationalRangesList.map(item => {
              if (item.id === action.payload.operationalRange.id) {
                item = action.payload.operationalRange;
              }
              return item;
            })
          : state.operationalRangesList,
        activeOperationalRange: action.payload.operationalRange,
        message: action.payload.message,
      };
    //=================================  ALTERING OPERATIONAL RANGE LOADING  ==================================//
    case ACTIONS.HANDLE_ALTERING_OPERATIONAL_RANGE_IN_PROGRESS:
      return {
        ...state,
        alterOperationalRangesInProgress: action.payload.inProgress,
        errorMessage: action.payload.error,
      };
    //=================================  DELETE OPERATIONAL RANGE  ==================================//
    case ACTIONS.HANDLE_SUCCESS_DELETE_OPERATIONAL_RANGE:
      return {
        ...state,
        operationalRangesList: action.payload,
      };
    //=================================  GET SINGLE OPERATIONAL RANGE ==================================//
    case ACTIONS.HANDLE_SUCCESS_GET_OPERATIONAL_RANGE:
      return { ...state, fetchedOperationalRange: action.payload };

    default:
      return state;
  }
};
