import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { i18nState } from 'redux-i18n';

import { auth } from './auth';
import { bikeBuddyScheduler } from './bike-buddy-scheduler';
import { forgotPassword } from './forgot_password';
import { resetPassword } from './reset_password';
import { acceptInvitation } from './accept-invitation';
import { confirmEmail } from './confirm_email';
import { profile } from './profile';
import { users } from './users';
import { roles } from './roles';
import { serviceItems } from './service_items';
import { serviceDetails } from './service-details';
import { competencies } from './competencies';
import { languages } from './languages';
import { bicycleTypes } from './bicycle-types';
import { promoCodes } from './promo-codes';
import { common } from './common';
import { serviceSlots } from './service-slots';
import { serviceSlotsUnreachable } from './service-slots-unreachable';
import { services } from './services';
import { subscriptions } from './subscriptions';
import { bicycles } from './bicycles';
import { availableBikeBuddy } from './available-bike-buddy';
import { dashboard } from './dashboard';
import { assets } from './assets';
import { operationalRanges } from './operational-ranges';
import { trialGroups } from './trial-group';
import { exportData } from './export-data';
import { products } from './products';
import { expert } from './expert';

const appReducer = combineReducers({
  i18nState,
  form: formReducer,
  auth,
  bikeBuddyScheduler,
  forgotPassword,
  resetPassword,
  acceptInvitation,
  confirmEmail,
  profile,
  users,
  roles,
  serviceItems,
  serviceDetails,
  competencies,
  languages,
  bicycleTypes,
  promoCodes,
  common,
  serviceSlots,
  serviceSlotsUnreachable,
  services,
  subscriptions,
  bicycles,
  availableBikeBuddy,
  dashboard,
  assets,
  operationalRanges,
  trialGroups,
  exportData,
  products,
  expert,
});

export const rootReducer = (state, action) => {
  return appReducer(state, action);
};
