import { ACTIONS } from '../constants/reset_password';

const INITIAL_STATE = {
  success: false,
  error: null,
  loading: false,
};

export const resetPassword = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.RESET_PASSWORD_START:
      return {
        ...state,
        success: false,
        error: null,
        loading: true,
      };
    case ACTIONS.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        success: action.payload,
        error: null,
        loading: false,
      };
    case ACTIONS.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
        loading: false,
      };
    case ACTIONS.RESET_RESET_PASSWORD:
      return {
        ...state,
        success: false,
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};
