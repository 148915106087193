import { ACTIONS } from '../constants/bike-buddy-scheduler';
import { MODE } from '../constants/mode';

const INITIAL_STATE = {
  bikeBuddySchedulerSlotsList: {
    bikeBuddySchedulerSlots: [],
    params: {},
    pager: null,
    error: null,
    loading: false,
  },
  activeBikeBuddySchedulerSlot: { bikeBuddySchedulerSlot: null, error: null, mode: null },
};

export const bikeBuddyScheduler = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.BIKE_BUDDY_SCHEDULER_SLOT_CREATE_RESET:
    case ACTIONS.BIKE_BUDDY_SCHEDULER_SLOT_UPDATE_RESET:
    case ACTIONS.BIKE_BUDDY_SCHEDULER_SLOT_DELETE_RESET:
    case ACTIONS.BIKE_BUDDY_SCHEDULER_SLOT_FETCH_RESET:
      return {
        ...state,
        activeBikeBuddySchedulerSlot: {
          bikeBuddySchedulerSlot: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //================================== BIKE BUDDY SCHEDULER SLOT CREATE START ==================================//
    case ACTIONS.BIKE_BUDDY_SCHEDULER_SLOT_CREATE_START:
      return {
        ...state,
        activeBikeBuddySchedulerSlot: {
          bikeBuddySchedulerSlot: null,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.BIKE_BUDDY_SCHEDULER_SLOT_CREATE_FAILURE:
      return {
        ...state,
        activeBikeBuddySchedulerSlot: {
          bikeBuddySchedulerSlot: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.BIKE_BUDDY_SCHEDULER_SLOT_CREATE_SUCCESS:
      return {
        ...state,
        bikeBuddySchedulerSlotsList: {
          ...state.bikeBuddySchedulerSlotsList,
          ...state.bikeBuddySchedulerSlots,
          error: null,
          loading: false,
        },
        activeBikeBuddySchedulerSlot: {
          bikeBuddySchedulerSlot: action.payload,
          loading: false,
          error: null,
          success: true,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.BIKE_BUDDY_SCHEDULER_SLOT_CREATE_OFF_SUCCESS:
      return {
        ...state,
        activeBikeBuddySchedulerSlot: {
          ...state.activeBikeBuddySchedulerSlot,
          success: false,
        },
      };

    //==================================  BIKE BUDDY SCHEDULER SLOT CREATE END  ==================================//
    //================================== BIKE BUDDY SCHEDULER SLOT UPDATE START ==================================//
    case ACTIONS.BIKE_BUDDY_SCHEDULER_SLOT_UPDATE_START:
      return {
        ...state,
        activeBikeBuddySchedulerSlot: {
          bikeBuddySchedulerSlot: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.BIKE_BUDDY_SCHEDULER_SLOT_UPDATE_FAILURE:
      return {
        ...state,
        activeBikeBuddySchedulerSlot: {
          bikeBuddySchedulerSlot: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.BIKE_BUDDY_SCHEDULER_SLOT_UPDATE_SUCCESS:
      return {
        ...state,
        bikeBuddySchedulerSlotsList: {
          ...state.bikeBuddySchedulerSlotsList,
          bikeBuddySchedulerSlots: state.bikeBuddySchedulerSlotsList.bikeBuddySchedulerSlots.map(
            slot => {
              if (slot.id === action.payload.id) {
                slot = action.payload;
              }
              return slot;
            }
          ),
          error: null,
          loading: false,
        },
        activeBikeBuddySchedulerSlot: {
          bikeBuddySchedulerSlot: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  BIKE BUDDY SCHEDULER SLOT UPDATE END  ==================================//
    //================================== BIKE BUDDY SCHEDULER SLOT DELETE START ==================================//
    case ACTIONS.BIKE_BUDDY_SCHEDULER_SLOT_DELETE_START:
      return {
        ...state,
        activeBikeBuddySchedulerSlot: {
          bikeBuddySchedulerSlot: action.payload,
          loading: true,
          error: null,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.BIKE_BUDDY_SCHEDULER_SLOT_DELETE_FAILURE:
      return {
        ...state,
        activeBikeBuddySchedulerSlot: {
          bikeBuddySchedulerSlot: null,
          loading: false,
          error: action.payload,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.BIKE_BUDDY_SCHEDULER_SLOT_DELETE_SUCCESS:
      return {
        ...state,
        bikeBuddySchedulerSlotsList: {
          ...state.bikeBuddySchedulerSlotsList,
          bikeBuddySchedulerSlots: state.bikeBuddySchedulerSlotsList.bikeBuddySchedulerSlots.filter(
            slot => slot.id !== action.payload.id
          ),
          error: null,
          loading: false,
        },
        activeBikeBuddySchedulerSlot: {
          bikeBuddySchedulerSlot: action.payload,
          loading: false,
          error: null,
          mode: MODE.DELETE,
        },
      };
    //==================================  BIKE BUDDY SCHEDULER SLOT DELETE END  ==================================//
    //================================== BIKE BUDDY SCHEDULER SLOT FETCH START ==================================//
    case ACTIONS.BIKE_BUDDY_SCHEDULER_SLOT_FETCH_START:
      return {
        ...state,
        activeBikeBuddySchedulerSlot: {
          bikeBuddySchedulerSlot: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.BIKE_BUDDY_SCHEDULER_SLOT_FETCH_FAILURE:
      return {
        ...state,
        activeBikeBuddySchedulerSlot: {
          bikeBuddySchedulerSlot: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.BIKE_BUDDY_SCHEDULER_SLOT_FETCH_SUCCESS:
      return {
        ...state,
        activeBikeBuddySchedulerSlot: {
          bikeBuddySchedulerSlot: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  BIKE BUDDY SCHEDULER SLOT FETCH END  ==================================//
    //================================== BIKE BUDDY SCHEDULER SLOTS FETCH START ==================================//
    case ACTIONS.BIKE_BUDDY_SCHEDULER_SLOTS_FETCH_START:
      return {
        ...state,
        bikeBuddySchedulerSlotsList: {
          bikeBuddySchedulerSlots: [],
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case ACTIONS.BIKE_BUDDY_SCHEDULER_SLOTS_FETCH_SUCCESS:
      return {
        ...state,
        bikeBuddySchedulerSlotsList: {
          ...state.bikeBuddySchedulerSlotsList,
          bikeBuddySchedulerSlots: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case ACTIONS.BIKE_BUDDY_SCHEDULER_SLOTS_FETCH_FAILURE:
      return {
        ...state,
        bikeBuddySchedulerSlotsList: {
          ...state.bikeBuddySchedulerSlotsList,
          bikeBuddySchedulerSlots: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case ACTIONS.BIKE_BUDDY_SCHEDULER_SLOTS_FETCH_RESET:
      return {
        ...state,
        bikeBuddySchedulerSlotsList: {
          bikeBuddySchedulerSlots: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  BIKE BUDDY SCHEDULER SLOTS FETCH END  ==================================//
    default:
      return state;
  }
};
