import jwt_decode from 'jwt-decode';

export const decodeToken = token => {
  const now = (new Date() / 1e3) | 0;
  const decoded = jwt_decode(token);

  if (decoded.exp < now) {
    decoded.error = 'Expired';
  }
  return decoded;
};
