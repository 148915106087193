import { ACTIONS } from '../constants/promo-codes';

const INITIAL_STATE = {
  promoCodesList: [],
  fetchingPromoCodes: false,
  error: null,
  paginator: {},
  showPromoCodeModal: false,
  promoCode: {},
  message: '',
  alterPromoCodesInProgress: false,
  errorMessage: '',
  fetchedPromoCode: {},
  users: [],
  usersPaginator: {},
};

export const promoCodes = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.GET_PROMO_CODES_IN_PROGRESS:
      return { ...state, fetchingPromoCodes: action.payload };
    case ACTIONS.HANDLE_ERROR_GET_PROMO_CODES:
      return { ...state, error: action.payload };
    case ACTIONS.HANDLE_SUCCESS_GET_PROMO_CODES:
      return {
        ...state,
        promoCodesList: action.payload.results,
        paginator: action.payload.paginator,
      };
    case ACTIONS.TOGGLE_PROMO_CODE_MODAL:
      return {
        ...state,
        showPromoCodeModal: action.payload.show,
        promoCode: action.payload.promoCode || {},
      };
    case ACTIONS.HANDLE_SUCCESS_ADD_PROMO_CODE:
      return {
        ...state,
        promoCodesList: action.payload.promoCode
          ? state.promoCodesList.concat(action.payload.promoCode)
          : state.promoCodesList,
        message: action.payload.message,
      };
    case ACTIONS.HANDLE_SUCCESS_UPDATE_PROMO_CODE:
      return {
        ...state,
        promoCodesList: action.payload.promoCode
          ? state.promoCodesList.map(item => {
              if (item.id === action.payload.promoCode.id) {
                item = action.payload.promoCode;
              }
              return item;
            })
          : state.promoCodesList,
        message: action.payload.message,
      };
    case ACTIONS.HANDLE_ALTERING_PROMO_CODE_IN_PROGRESS:
      return {
        ...state,
        alterPromoCodesInProgress: action.payload.inProgress,
        errorMessage: action.payload.error,
      };
    case ACTIONS.HANDLE_SUCCESS_DELETE_PROMO_CODE:
      return {
        ...state,
        promoCodesList: action.payload,
      };
    case ACTIONS.HANDLE_SUCCESS_GET_PROMO_CODE:
      return { ...state, fetchedPromoCode: action.payload };
    case ACTIONS.HANDLE_SUCCESS_GET_PROMO_CODE_USERS:
      return { ...state, users: action.payload.results, usersPaginator: action.payload.paginator };
    default:
      return state;
  }
};
