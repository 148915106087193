import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Footer extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  };

  render() {
    return (
      <footer className="app-footer">
        <span className="text-muted">{this.context.t('APP.FOOTER.TEXT')}</span>
        <span className="ml-auto text-muted">{this.context.t('APP.VERSION')}</span>
      </footer>
    );
  }
}

export default Footer;
