import { ACTIONS } from '../constants/service-slots';

const INITIAL_STATE = {
  serviceSlotsList: [],
  paginator: {},
  error: null,
  loading: false,
  showServiceSlotModal: false,
  serviceSlot: {},
  message: '',
};

export const serviceSlots = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.HANDLE_LOADING_SERVICE_SLOTS:
      return { ...state, loading: action.payload };
    case ACTIONS.HANDLE_SUCCESS_GET_SERVICE_SLOTS: {
      return {
        ...state,
        serviceSlotsList: action.payload.results,
        paginator: action.payload.paginator,
      };
    }
    case ACTIONS.HANDLE_ERROR_GET_SERVICE_SLOTS:
      return { ...state, error: action.payload };
    case ACTIONS.TOGGLE_SERVICE_SLOT_MODAL:
      return {
        ...state,
        serviceSlot: action.payload.serviceSlot || {},
        showServiceSlotModal: action.payload.show,
      };
    case ACTIONS.HANDLE_SUCCESS_ADD_SERVICE_SLOT:
      return {
        ...state,
        serviceSlotsList: action.payload.serviceSlot
          ? state.serviceSlotsList.concat(action.payload.serviceSlot)
          : state.serviceSlotsList,
        message: action.payload.message,
      };
    case ACTIONS.HANDLE_SUCCESS_UPDATE_SERVICE_SLOT:
      return {
        ...state,
        serviceSlotsList: action.payload.serviceSlot
          ? state.serviceSlotsList.map(item => {
              if (item.id === action.payload.serviceSlot.id) {
                item = action.payload.serviceSlot;
              }
              return item;
            })
          : state.serviceSlotsList,
        message: action.payload.message,
      };
    case ACTIONS.HANDLE_ALTER_SERVICE_SLOT_IN_PROGRESS:
      return {
        ...state,
        loading: action.payload.inProgress,
        error: action.payload.error,
      };
    case ACTIONS.HANDLE_SUCCESS_DELETE_SERVICE_SLOT:
      return {
        ...state,
        serviceSlotsList: action.payload,
      };
    default:
      return state;
  }
};
