export const ACTIONS = {
  BICYCLE_TYPES_FETCH_START: 'BICYCLE_TYPES_FETCH_START',
  BICYCLE_TYPES_FETCH_SUCCESS: 'BICYCLE_TYPES_FETCH_SUCCESS',
  BICYCLE_TYPES_FETCH_FAILURE: 'BICYCLE_TYPES_FETCH_FAILURE',
  BICYCLE_TYPES_FETCH_RESET: 'BICYCLE_TYPES_FETCH_RESET',

  BICYCLE_TYPE_FETCH_START: 'BICYCLE_TYPE_FETCH_START',
  BICYCLE_TYPE_FETCH_SUCCESS: 'BICYCLE_TYPE_FETCH_SUCCESS',
  BICYCLE_TYPE_FETCH_FAILURE: 'BICYCLE_TYPE_FETCH_FAILURE',
  BICYCLE_TYPE_FETCH_RESET: 'BICYCLE_TYPE_FETCH_RESET',

  BICYCLE_TYPE_CREATE_START: 'BICYCLE_TYPE_CREATE_START',
  BICYCLE_TYPE_CREATE_SUCCESS: 'BICYCLE_TYPE_CREATE_SUCCESS',
  BICYCLE_TYPE_CREATE_FAILURE: 'BICYCLE_TYPE_CREATE_FAILURE',
  BICYCLE_TYPE_CREATE_RESET: 'BICYCLE_TYPE_CREATE_RESET',

  BICYCLE_TYPE_UPDATE_START: 'BICYCLE_TYPE_UPDATE_START',
  BICYCLE_TYPE_UPDATE_SUCCESS: 'BICYCLE_TYPE_UPDATE_SUCCESS',
  BICYCLE_TYPE_UPDATE_FAILURE: 'BICYCLE_TYPE_UPDATE_FAILURE',
  BICYCLE_TYPE_UPDATE_RESET: 'BICYCLE_TYPE_UPDATE_RESET',

  BICYCLE_TYPE_DELETE_START: 'BICYCLE_TYPE_DELETE_START',
  BICYCLE_TYPE_DELETE_SUCCESS: 'BICYCLE_TYPE_DELETE_SUCCESS',
  BICYCLE_TYPE_DELETE_FAILURE: 'BICYCLE_TYPE_DELETE_FAILURE',
  BICYCLE_TYPE_DELETE_RESET: 'BICYCLE_TYPE_DELETE_RESET',
};
