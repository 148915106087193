import React, { Component } from 'react';

class SidebarForm extends Component {
  render() {
    return (
      //   <div className="sidebar-form"></div>
      <div />
    );
  }
}

export default SidebarForm;
