export const ACTIONS = {
  SERVICE_DETAILS_FETCH_START: 'SERVICE_DETAILS_FETCH_START',
  SERVICE_DETAILS_FETCH_SUCCESS: 'SERVICE_DETAILS_FETCH_SUCCESS',
  SERVICE_DETAILS_FETCH_FAILURE: 'SERVICE_DETAILS_FETCH_FAILURE',
  SERVICE_DETAILS_FETCH_RESET: 'SERVICE_DETAILS_FETCH_RESET',

  SERVICE_DETAIL_FETCH_START: 'SERVICE_DETAIL_FETCH_START',
  SERVICE_DETAIL_FETCH_SUCCESS: 'SERVICE_DETAIL_FETCH_SUCCESS',
  SERVICE_DETAIL_FETCH_FAILURE: 'SERVICE_DETAIL_FETCH_FAILURE',
  SERVICE_DETAIL_FETCH_RESET: 'SERVICE_DETAIL_FETCH_RESET',

  SERVICE_DETAIL_CREATE_START: 'SERVICE_DETAIL_CREATE_START',
  SERVICE_DETAIL_CREATE_SUCCESS: 'SERVICE_DETAIL_CREATE_SUCCESS',
  SERVICE_DETAIL_CREATE_FAILURE: 'SERVICE_DETAIL_CREATE_FAILURE',
  SERVICE_DETAIL_CREATE_RESET: 'SERVICE_DETAIL_CREATE_RESET',

  SERVICE_DETAIL_UPDATE_START: 'SERVICE_DETAIL_UPDATE_START',
  SERVICE_DETAIL_UPDATE_SUCCESS: 'SERVICE_DETAIL_UPDATE_SUCCESS',
  SERVICE_DETAIL_UPDATE_FAILURE: 'SERVICE_DETAIL_UPDATE_FAILURE',
  SERVICE_DETAIL_UPDATE_RESET: 'SERVICE_DETAIL_UPDATE_RESET',

  SERVICE_DETAIL_DELETE_START: 'SERVICE_DETAIL_DELETE_START',
  SERVICE_DETAIL_DELETE_SUCCESS: 'SERVICE_DETAIL_DELETE_SUCCESS',
  SERVICE_DETAIL_DELETE_FAILURE: 'SERVICE_DETAIL_DELETE_FAILURE',
  SERVICE_DETAIL_DELETE_RESET: 'SERVICE_DETAIL_DELETE_RESET',

  SERVICE_DETAILS_UPDATE_START: 'SERVICE_DETAILS_UPDATE_START',
  SERVICE_DETAILS_UPDATE_SUCCESS: 'SERVICE_DETAILS_UPDATE_SUCCESS',
  SERVICE_DETAILS_UPDATE_FAILURE: 'SERVICE_DETAILS_UPDATE_FAILURE',
  SERVICE_DETAILS_UPDATE_RESET: 'SERVICE_DETAILS_UPDATE_RESET',
};
