export const ACTIONS = {
  USERS_FETCH_START: 'USERS_FETCH_START',
  USERS_FETCH_SUCCESS: 'USERS_FETCH_SUCCESS',
  USERS_FETCH_FAILURE: 'USERS_FETCH_FAILURE',
  USERS_FETCH_RESET: 'USERS_FETCH_RESET',

  BUDDIES_FETCH_START: 'BUDDIES_FETCH_START',
  BUDDIES_FETCH_SUCCESS: 'BUDDIES_FETCH_SUCCESS',
  BUDDIES_FETCH_FAILURE: 'BUDDIES_FETCH_FAILURE',
  BUDDIES_FETCH_RESET: 'BUDDIES_FETCH_RESET',

  USER_FETCH_START: 'USER_FETCH_START',
  USER_FETCH_SUCCESS: 'USER_FETCH_SUCCESS',
  USER_FETCH_FAILURE: 'USER_FETCH_FAILURE',
  USER_FETCH_RESET: 'USER_FETCH_RESET',

  USER_CREATE_START: 'USER_CREATE_START',
  USER_CREATE_SUCCESS: 'USER_CREATE_SUCCESS',
  USER_CREATE_FAILURE: 'USER_CREATE_FAILURE',
  USER_CREATE_RESET: 'USER_CREATE_RESET',

  USER_UPDATE_START: 'USER_UPDATE_START',
  USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  USER_UPDATE_FAILURE: 'USER_UPDATE_FAILURE',
  USER_UPDATE_RESET: 'USER_UPDATE_RESET',

  USER_DELETE_START: 'USER_DELETE_START',
  USER_DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
  USER_DELETE_FAILURE: 'USER_DELETE_FAILURE',
  USER_DELETE_RESET: 'USER_DELETE_RESET',

  USER_INVITE_START: 'USER_INVITE_START',
  USER_INVITE_SUCCESS: 'USER_INVITE_SUCCESS',
  USER_INVITE_FAILURE: 'USER_INVITE_FAILURE',
  USER_INVITE_RESET: 'USER_INVITE_RESET',

  USER_BULK_INVITE_START: 'USER_BULK_INVITE_START',
  USER_BULK_INVITE_SUCCESS: 'USER_BULK_INVITE_SUCCESS',
  USER_BULK_INVITE_FAILURE: 'USER_BULK_INVITE_FAILURE',
  USER_BULK_INVITE_RESET: 'USER_BULK_INVITE_RESET',

  USER_ROLES_FETCH_START: 'USER_ROLES_FETCH_START',
  USER_ROLES_FETCH_SUCCESS: 'USER_ROLES_FETCH_SUCCESS',
  USER_ROLES_FETCH_FAILURE: 'USER_ROLES_FETCH_FAILURE',
  USER_ROLES_FETCH_RESET: 'USER_ROLES_FETCH_RESET',

  USER_ROLE_REMOVE_START: 'USER_ROLE_REMOVE_START',
  USER_ROLE_REMOVE_SUCCESS: 'USER_ROLE_REMOVE_SUCCESS',
  USER_ROLE_REMOVE_FAILURE: 'USER_ROLE_REMOVE_FAILURE',
  USER_ROLE_REMOVE_RESET: 'USER_ROLE_REMOVE_RESET',

  USER_ROLE_ADD_START: 'USER_ROLE_ADD_START',
  USER_ROLE_ADD_SUCCESS: 'USER_ROLE_ADD_SUCCESS',
  USER_ROLE_ADD_FAILURE: 'USER_ROLE_ADD_FAILURE',
  USER_ROLE_ADD_RESET: 'USER_ROLE_ADD_RESET',

  USER_BICYCLES_FETCH_START: 'USER_BICYCLES_FETCH_START',
  USER_BICYCLES_FETCH_SUCCESS: 'USER_BICYCLES_FETCH_SUCCESS',
  USER_BICYCLES_FETCH_FAILURE: 'USER_BICYCLES_FETCH_FAILURE',
  USER_BICYCLES_FETCH_RESET: 'USER_BICYCLES_FETCH_RESET',

  USER_BICYCLES_UPDATE_SUCCESS: 'USER_BICYCLES_UPDATE_SUCCESS',
  USER_BICYCLES_UPDATE_FAILURE: 'USER_BICYCLES_UPDATE_FAILURE',

  USER_COMPETENCIES_FETCH_START: 'USER_COMPETENCIES_FETCH_START',
  USER_COMPETENCIES_FETCH_SUCCESS: 'USER_COMPETENCIES_FETCH_SUCCESS',
  USER_COMPETENCIES_FETCH_FAILURE: 'USER_COMPETENCIES_FETCH_FAILURE',
  USER_COMPETENCIES_FETCH_RESET: 'USER_COMPETENCIES_FETCH_RESET',

  USER_COMPETENCE_REMOVE_START: 'USER_COMPETENCE_REMOVE_START',
  USER_COMPETENCE_REMOVE_SUCCESS: 'USER_COMPETENCE_REMOVE_SUCCESS',
  USER_COMPETENCE_REMOVE_FAILURE: 'USER_COMPETENCE_REMOVE_FAILURE',
  USER_COMPETENCE_REMOVE_RESET: 'USER_COMPETENCE_REMOVE_RESET',

  USER_COMPETENCE_ADD_START: 'USER_COMPETENCE_ADD_START',
  USER_COMPETENCE_ADD_SUCCESS: 'USER_COMPETENCE_ADD_SUCCESS',
  USER_COMPETENCE_ADD_FAILURE: 'USER_COMPETENCE_ADD_FAILURE',
  USER_COMPETENCE_ADD_RESET: 'USER_COMPETENCE_ADD_RESET',

  USER_LANGUAGES_FETCH_START: 'USER_LANGUAGES_FETCH_START',
  USER_LANGUAGES_FETCH_SUCCESS: 'USER_LANGUAGES_FETCH_SUCCESS',
  USER_LANGUAGES_FETCH_FAILURE: 'USER_LANGUAGES_FETCH_FAILURE',
  USER_LANGUAGES_FETCH_RESET: 'USER_LANGUAGES_FETCH_RESET',

  USER_LANGUAGE_REMOVE_START: 'USER_LANGUAGE_REMOVE_START',
  USER_LANGUAGE_REMOVE_SUCCESS: 'USER_LANGUAGE_REMOVE_SUCCESS',
  USER_LANGUAGE_REMOVE_FAILURE: 'USER_LANGUAGE_REMOVE_FAILURE',
  USER_LANGUAGE_REMOVE_RESET: 'USER_LANGUAGE_REMOVE_RESET',

  USER_LANGUAGE_ADD_START: 'USER_LANGUAGE_ADD_START',
  USER_LANGUAGE_ADD_SUCCESS: 'USER_LANGUAGE_ADD_SUCCESS',
  USER_LANGUAGE_ADD_FAILURE: 'USER_LANGUAGE_ADD_FAILURE',
  USER_LANGUAGE_ADD_RESET: 'USER_LANGUAGE_ADD_RESET',

  USER_OPERATIONAL_RANGE_FETCH_START: 'USER_OPERATIONAL_RANGE_FETCH_START',
  USER_OPERATIONAL_RANGE_FETCH_SUCCESS: 'USER_OPERATIONAL_RANGE_FETCH_SUCCESS',
  USER_OPERATIONAL_RANGE_FETCH_FAILURE: 'USER_OPERATIONAL_RANGE_FETCH_FAILURE',
  USER_OPERATIONAL_RANGE_FETCH_RESET: 'USER_OPERATIONAL_RANGE_FETCH_RESET',

  USER_SERVICES_FETCH_START: 'USER_SERVICES_FETCH_START',
  USER_SERVICES_FETCH_SUCCESS: 'USER_SERVICES_FETCH_SUCCESS',
  USER_SERVICES_FETCH_FAILURE: 'USER_SERVICES_FETCH_FAILURE',
  USER_SERVICES_FETCH_RESET: 'USER_SERVICES_FETCH_RESET',
};
