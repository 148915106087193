import { ACTIONS } from '../constants/expert';

const INITIAL_STATE = {
  userList: { users: [], params: {}, pager: null, error: null, loading: false },
};

export const expert = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //================================== EXPERT FETCH START ==================================//
    case ACTIONS.EXPERT_FETCH_START:
      return {
        ...state,
        userList: {
          users: [],
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case ACTIONS.EXPERT_FETCH_SUCCESS:
      return {
        ...state,
        userList: {
          ...state.userList,
          users: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case ACTIONS.EXPERT_FETCH_FAILURE:
      return {
        ...state,
        userList: {
          ...state.userList,
          users: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case ACTIONS.EXPERT_FETCH_RESET:
      return {
        ...state,
        userList: {
          users: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };

    case ACTIONS.EXPERT_CANCEL_START:
      return {
        ...state,
        userList: {
          ...state.userList,
          error: null,
          loading: true,
        },
      };
    case ACTIONS.EXPERT_CANCEL_SUCCESS:
      return {
        ...state,
        userList: {
          ...state.userList,
          error: null,
          loading: false,
        },
      };
    case ACTIONS.EXPERT_CANCEL_FAILURE:
      return {
        ...state,
        userList: {
          ...state.userList,
          error: action.payload,
          loading: false,
        },
      };

    default:
      return state;
  }
};
