import { ACTIONS } from '../constants/services';
import { MODE } from '../constants/mode';

const INITIAL_STATE = {
  servicesList: { services: [], params: {}, pager: null, error: null, loading: false },
  activeService: { service: null, error: null, mode: null, loading: false },
};

export const services = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.SERVICE_CREATE_RESET:
    case ACTIONS.SERVICE_UPDATE_RESET:
    case ACTIONS.SERVICE_DELETE_RESET:
    case ACTIONS.SERVICE_FETCH_RESET:
      return {
        ...state,
        activeService: {
          service: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //================================== SERVICE CREATE START ==================================//
    case ACTIONS.SERVICE_CREATE_START:
      return {
        ...state,
        activeService: {
          service: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.SERVICE_CREATE_FAILURE:
      return {
        ...state,
        activeService: {
          service: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.SERVICE_CREATE_SUCCESS:
      return {
        ...state,
        servicesList: {
          ...state.servicesList,
          services: state.servicesList.services.concat(action.payload),
          error: null,
          loading: false,
        },
        activeService: {
          service: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    //==================================  SERVICE CREATE END  ==================================//
    //================================== SERVICE UPDATE START ==================================//
    case ACTIONS.SERVICE_UPDATE_START:
      return {
        ...state,
        activeService: {
          service: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.SERVICE_UPDATE_FAILURE:
      return {
        ...state,
        activeService: {
          service: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.SERVICE_UPDATE_SUCCESS:
      return {
        ...state,
        servicesList: {
          ...state.servicesList,
          services: state.servicesList.services.map(perm => {
            if (perm.id === action.payload.id) {
              perm = action.payload;
            }
            return perm;
          }),
          error: null,
          loading: false,
        },
        activeService: {
          service: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  SERVICE UPDATE END  ==================================//
    //================================== SERVICE DELETE START ==================================//
    case ACTIONS.SERVICE_DELETE_START:
      return {
        ...state,
        activeService: {
          service: action.payload,
          loading: true,
          error: null,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.SERVICE_DELETE_FAILURE:
      return {
        ...state,
        activeService: {
          service: null,
          loading: false,
          error: action.payload,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.SERVICE_DELETE_SUCCESS:
      return {
        ...state,
        servicesList: {
          ...state.servicesList,
          services: state.servicesList.services.filter(service => service.id !== action.payload.id),
          error: null,
          loading: false,
        },
        activeService: {
          service: action.payload,
          loading: false,
          error: null,
          mode: MODE.DELETE,
        },
      };
    //==================================  SERVICE DELETE END  ==================================//
    //================================== SERVICE FETCH START ==================================//
    case ACTIONS.SERVICE_FETCH_START:
      return {
        ...state,
        activeService: {
          service: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.SERVICE_FETCH_FAILURE:
      return {
        ...state,
        activeService: {
          service: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.SERVICE_FETCH_SUCCESS:
      return {
        ...state,
        activeService: {
          service: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  SERVICE FETCH END  ==================================//
    //================================== SERVICES FETCH START ==================================//
    case ACTIONS.SERVICES_FETCH_START:
      return {
        ...state,
        servicesList: {
          services: [],
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case ACTIONS.SERVICES_FETCH_SUCCESS:
      return {
        ...state,
        servicesList: {
          ...state.servicesList,
          services: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case ACTIONS.SERVICES_FETCH_FAILURE:
      return {
        ...state,
        servicesList: {
          ...state.servicesList,
          services: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case ACTIONS.SERVICES_FETCH_RESET:
      return {
        ...state,
        servicesList: {
          services: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  SERVICES FETCH END  ==================================//
    default:
      return state;
  }
};
