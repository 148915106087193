import { ACTIONS } from '../constants/common';

const INITIAL_STATE = {
  uploadInProgress: false,
  accessUrl: '',
  uploadUrl: '',
  showConfirmModal: false,
  confirmModalTitle: '',
  confirmModalMessage: '',
  confirmModalOnConfirm: null,
};

export const common = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.HANDLE_UPLOAD_IN_PROGRESS:
      return { ...state, uploadInProgress: action.payload };
    case ACTIONS.HANDLE_SUCCESS_UPLOAD_URL:
      return { ...state, accessUrl: action.payload };
    case ACTIONS.HANDLE_SUCCESS_GET_UPLOAD_URL:
      return { ...state, uploadUrl: action.payload };
    case ACTIONS.TOGGLE_CONFIRM_MODAL:
      return {
        ...state,
        showConfirmModal: action.payload.show,
        confirmModalTitle: action.payload.title,
        confirmModalMessage: action.payload.message,
        confirmModalOnConfirm: action.payload.onConfirm,
      };
    default:
      return state;
  }
};
