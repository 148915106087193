import { ReactComponent as WrenchMinus } from '../img/icons/wrench-minus.svg';
import { ReactComponent as WrenchPlus } from '../img/icons/wrench-plus.svg';
import { ReactComponent as CheckRounded } from '../img/icons/check-rounded.svg';

export const ACTIONS = {
  SERVICE_ITEMS_FETCH_START: 'SERVICE_ITEMS_FETCH_START',
  SERVICE_ITEMS_FETCH_SUCCESS: 'SERVICE_ITEMS_FETCH_SUCCESS',
  SERVICE_ITEMS_FETCH_FAILURE: 'SERVICE_ITEMS_FETCH_FAILURE',
  SERVICE_ITEMS_FETCH_RESET: 'SERVICE_ITEMS_FETCH_RESET',

  SERVICE_ITEM_FETCH_START: 'SERVICE_ITEM_FETCH_START',
  SERVICE_ITEM_FETCH_SUCCESS: 'SERVICE_ITEM_FETCH_SUCCESS',
  SERVICE_ITEM_FETCH_FAILURE: 'SERVICE_ITEM_FETCH_FAILURE',
  SERVICE_ITEM_FETCH_RESET: 'SERVICE_ITEM_FETCH_RESET',

  SERVICE_ITEM_CREATE_START: 'SERVICE_ITEM_CREATE_START',
  SERVICE_ITEM_CREATE_SUCCESS: 'SERVICE_ITEM_CREATE_SUCCESS',
  SERVICE_ITEM_CREATE_FAILURE: 'SERVICE_ITEM_CREATE_FAILURE',
  SERVICE_ITEM_CREATE_RESET: 'SERVICE_ITEM_CREATE_RESET',

  SERVICE_ITEM_UPDATE_START: 'SERVICE_ITEM_UPDATE_START',
  SERVICE_ITEM_UPDATE_SUCCESS: 'SERVICE_ITEM_UPDATE_SUCCESS',
  SERVICE_ITEM_UPDATE_FAILURE: 'SERVICE_ITEM_UPDATE_FAILURE',
  SERVICE_ITEM_UPDATE_RESET: 'SERVICE_ITEM_UPDATE_RESET',

  SERVICE_ITEM_DELETE_START: 'SERVICE_ITEM_DELETE_START',
  SERVICE_ITEM_DELETE_SUCCESS: 'SERVICE_ITEM_DELETE_SUCCESS',
  SERVICE_ITEM_DELETE_FAILURE: 'SERVICE_ITEM_DELETE_FAILURE',
  SERVICE_ITEM_DELETE_RESET: 'SERVICE_ITEM_DELETE_RESET',
};

export const SERVICE_ITEMS = {
  needRepair: {
    title: 'Needs repair',
    service_detail_item_id: 1,
    icon: WrenchMinus,
    active_class: 'icon-red',
  },
  adjusted: {
    title: 'Adjusted & OK',
    service_detail_item_id: 2,
    icon: WrenchPlus,
    active_class: 'icon-green',
  },
  controlled: {
    title: 'Controlled & OK',
    service_detail_item_id: 3,
    icon: CheckRounded,
    active_class: 'icon-green',
  },
};
