export const objectToQueryParams = obj => {
  if (!obj) return '';
  const searchParams = new URLSearchParams();
  Object.keys(obj).forEach(key => searchParams.append(key, obj[key]));
  return searchParams.toString();
};

export const objectToQueryParamsV2 = obj => {
  if (!obj) return '';
  let params = '';
  Object.keys(obj).forEach(key => {
    params += params.length > 0 ? '&' : '';
    params += key + '=' + obj[key];
  });
  return params;
};
