import { ACTIONS } from '../constants/auth';

const INITIAL_STATE = {
  token: typeof localStorage.getItem('token') === 'string' ? localStorage.getItem('token') : null,
  loggedIn: typeof localStorage.getItem('token') === 'string',
  claims: [],
  error: null,
  loading: false,
};

export const auth = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.LOGIN_START:
      return {
        ...state,
        token: null,
        loggedIn: false,
        error: null,
        loading: true,
      };
    case ACTIONS.LOGIN_SUCCESS:
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('refresh_token', action.payload.refresh_token);
      return {
        ...state,
        token: action.payload.token,
        loggedIn: true,
        error: null,
        loading: false,
      };
    case ACTIONS.LOGIN_FAILURE:
      return {
        ...state,
        token: null,
        loggedIn: false,
        error: action.payload,
        loading: false,
      };
    case ACTIONS.RESET_LOGIN:
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      return {
        ...state,
        token: null,
        loggedIn: false,
        error: null,
        loading: false,
      };
    case ACTIONS.ADD_CLAIMS:
      return {
        ...state,
        claims: action.payload,
      };
    default:
      return state;
  }
};
