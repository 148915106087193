import React, { Component } from 'react';
import { Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ToastContainer, toast, Slide } from 'react-toastify';
import PubSub from 'pubsub-js';
import { setLanguage } from 'redux-i18n';

import AppRoute from '../helpers/app-route';
import RenderIf from '../helpers/render-if';
import ConfirmModal from '../components/confirm-modal/confirm-modal';
import Header from '../components/header/header';
import Sidebar from '../components/sidebar/sidebar';
import Footer from '../components/footer/footer';
import { profileFetch } from '../actions/profile';
import { resetLogin, addClaims } from '../actions/auth';
import { decodeToken } from '../helpers/jwt';
import config from '../config/config';
import { TOAST_KEY_WORD, TOAST_TYPES } from '../constants/common';

import '../styles/vendors/react-select/react-select.css';
// import react ladda
import 'ladda/dist/ladda-themeless.min.css';
// import react toastify styles
import 'react-toastify/dist/ReactToastify.css';
// import spinkit styles
import 'spinkit/css/spinkit.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import '../styles/style.css';

class ShellContainer extends Component {
  static propTypes = {
    routes: PropTypes.array.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func.isRequired,
  };

  subscriptions = [];

  constructor() {
    super();
    const ctx = this;
    // create pubSub subscription for 'TOAST' events
    this.subscriptions = [
      PubSub.subscribe(TOAST_KEY_WORD, (topic, payload) => ctx.onToastReceived(topic, payload)),
    ];
  }

  // toast subscription handler
  onToastReceived = (topic, payload) => {
    const message = this.context.t(payload.message || '');

    switch (payload.type) {
      case TOAST_TYPES.info:
        toast.info(message);
        return;
      case TOAST_TYPES.success:
        toast.success(message);
        return;
      case TOAST_TYPES.warning:
        toast.warn(message);
        return;
      case TOAST_TYPES.error:
        toast.error(message);
        return;
      default:
        toast(message);
    }
  };

  componentWillMount = () => {
    if (this.props.loggedIn) {
      this.props.profileFetch();
    }
  };

  componentWillUnmount = () => this.subscriptions.forEach(PubSub.unsubscribe);

  onLanguageClickHandler = lang => {
    localStorage.setItem('lang', lang.code);
    this.props.setLanguage(lang.code);
  };

  onLogoutClickHandler = () => {
    this.props.resetLogin();
    this.props.history.push('/login');
  };

  onProfileClickHandler = () => {
    this.props.history.push('/profile');
  };

  getCurrentLanguage = () => {
    return config.languages.find(lang => lang.code === this.props.lang);
  };

  renderRoutes = () => {
    return this.props.routes.map((route, index) => {
      return <AppRoute key={index} {...route} />;
    });
  };

  renderSidebar = permissions => {
    const sidebarItems = this.props.routes
      .filter(r => r.sidebar != null && r.sidebar === true)
      .map((r, index) => ({
        path: r.path,
        name: r.name,
        icon: r.icon,
        children: r.sidebarChildren,
        permissions: r.permissions,
      }));

    return <Sidebar permissions={permissions} location={this.props.location} nav={sidebarItems} />;
  };

  render = () => {
    const { token, loggedIn, currentUser, match } = this.props;
    let roles = [];
    if (loggedIn && token) {
      // verify and decode jwt token
      const decoded = decodeToken(token);

      // check if token is expired
      // if (decoded.error === 'Expired') {
      //   this.props.resetLogin();
      //   return <Redirect to="/login" />;
      // }
      // check if user has proper claims (roles)
      roles = config.whitelistedRoles.filter(r => decoded.scope.includes(r));
      this.props.addClaims(roles);
      const shouldRedirect = match.url === window.location.pathname;
      if (roles.length === 0 && shouldRedirect) {
        return <Redirect to="/forbidden" />;
      }
    }

    const containerStyle = { zIndex: 1999 };
    return (
      <div>
        <RenderIf condition={loggedIn && roles.length > 0}>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            style={containerStyle}
            transition={Slide}
            hideProgressBar={true}
          />
          <ConfirmModal />
          <div className="app">
            <Header
              user={currentUser.user}
              languages={config.languages}
              currentLanguage={this.getCurrentLanguage()}
              onLanguageClick={this.onLanguageClickHandler}
              onLogoutClick={this.onLogoutClickHandler}
              onProfileClick={this.onProfileClickHandler}
            />
            <div className="app-body">
              {this.renderSidebar(roles)}
              <main className="main">
                <div className="container-fluid">
                  <Switch>{this.renderRoutes()}</Switch>
                </div>
              </main>
            </div>
            <Footer />
          </div>
        </RenderIf>
        <RenderIf condition={!loggedIn || roles.length === 0}>
          <Switch>{this.renderRoutes()}</Switch>
        </RenderIf>
      </div>
    );
  };
}

const mapStateToProps = state => ({
  lang: state.i18nState.lang,
  token: state.auth.token,
  loggedIn: state.auth.loggedIn,
  currentUser: state.profile,
});

const mapDispatchToProps = dispatch => ({
  profileFetch: () => {
    dispatch(profileFetch());
  },
  resetLogin: () => {
    dispatch(resetLogin());
  },
  setLanguage: lang => {
    dispatch(setLanguage(lang));
  },
  addClaims: claims => {
    dispatch(addClaims(claims));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShellContainer));
