import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Nav, NavbarToggler, NavbarBrand } from 'reactstrap';

import UserDropdown from './user-dropdown';
//import LanguagesDropdown from './languages-dropdown';

class Header extends Component {
  static propTypes = {
    user: PropTypes.object,
    languages: PropTypes.array,
    onLogoutClick: PropTypes.func,
    onProfileClick: PropTypes.func,
    currentLanguage: PropTypes.object,
    onLanguageClick: PropTypes.func,
  };

  sidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-hidden');
  }

  sidebarMinimize(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-minimized');
  }

  mobileSidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-show');
  }

  render() {
    return (
      <header className="app-header navbar">
        <NavbarToggler className="d-lg-none" onClick={this.mobileSidebarToggle}>
          <span className="navbar-toggler-icon" />
        </NavbarToggler>
        <NavbarBrand href="#" />
        <NavbarToggler className="d-md-down-none" onClick={this.sidebarToggle}>
          <span className="navbar-toggler-icon" />
        </NavbarToggler>
        <Nav className="ml-auto mr-4" navbar>
          {/* <LanguagesDropdown
            currentLanguage={this.props.currentLanguage}
            languages={this.props.languages}
            onLanguageClick={this.props.onLanguageClick}
          /> */}
          <UserDropdown
            user={this.props.user}
            onLogoutClick={this.props.onLogoutClick}
            onProfileClick={this.props.onProfileClick}
          />
        </Nav>
      </header>
    );
  }
}

export default Header;
