import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

class ProtectedRoute extends Component {
  renderRoute = props => {
    const { component: Component, loggedIn, routes } = this.props;
    if (loggedIn) {
      return <Component {...props} routes={routes} />;
    } else {
      return (
        <Redirect
          to={{
            pathname: '/login',
            search: (props.location && props.location.search) || null,
            state: { from: props.location },
          }}
        />
      );
    }
  };

  render = () => {
    const { component: Component, loggedIn, routes, ...rest } = this.props;
    return <Route {...rest} render={props => this.renderRoute(props)} />;
  };
}

const mapStateToProps = state => {
  return {
    loggedIn: state.auth.loggedIn,
  };
};

export default connect(mapStateToProps, null)(ProtectedRoute);
