import { ACTIONS } from '../constants/assets';
import { MODE } from '../constants/mode';

const INITIAL_STATE = {
  assetsList: { assets: [], params: {}, pager: null, error: null, loading: false },
  activeAsset: { asset: null, error: null, mode: null },
};

export const assets = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.ASSET_CREATE_RESET:
    case ACTIONS.ASSET_UPDATE_RESET:
    case ACTIONS.ASSET_DELETE_RESET:
    case ACTIONS.ASSET_FETCH_RESET:
      return {
        ...state,
        activeAsset: {
          asset: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //================================== ASSET CREATE START ==================================//
    case ACTIONS.ASSET_CREATE_START:
      return {
        ...state,
        activeAsset: {
          asset: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.ASSET_CREATE_FAILURE:
      return {
        ...state,
        activeAsset: {
          asset: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.ASSET_CREATE_SUCCESS:
      return {
        ...state,
        assetsList: {
          ...state.assetsList,
          assets: state.assetsList.assets.concat(action.payload),
          error: null,
          loading: false,
        },
        activeAsset: {
          asset: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    //==================================  ASSET CREATE END  ==================================//
    //================================== ASSET UPDATE START ==================================//
    case ACTIONS.ASSET_UPDATE_START:
      return {
        ...state,
        activeAsset: {
          asset: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.ASSET_UPDATE_FAILURE:
      return {
        ...state,
        activeAsset: {
          asset: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.ASSET_UPDATE_SUCCESS:
      return {
        ...state,
        assetsList: {
          ...state.assetsList,
          assets: state.assetsList.assets.map(perm => {
            if (perm.id === action.payload.id) {
              perm = action.payload;
            }
            return perm;
          }),
          error: null,
          loading: false,
        },
        activeAsset: {
          asset: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  ASSET UPDATE END  ==================================//
    //================================== ASSET DELETE START ==================================//
    case ACTIONS.ASSET_DELETE_START:
      return {
        ...state,
        activeAsset: {
          asset: action.payload,
          loading: true,
          error: null,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.ASSET_DELETE_FAILURE:
      return {
        ...state,
        activeAsset: {
          asset: null,
          loading: false,
          error: action.payload,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.ASSET_DELETE_SUCCESS:
      return {
        ...state,
        assetsList: {
          ...state.assetsList,
          assets: state.assetsList.assets.filter(asset => asset.id !== action.payload.id),
          error: null,
          loading: false,
        },
        activeAsset: {
          asset: action.payload,
          loading: false,
          error: null,
          mode: MODE.DELETE,
        },
      };
    //==================================  ASSET DELETE END  ==================================//
    //================================== ASSET FETCH START ==================================//
    case ACTIONS.ASSET_FETCH_START:
      return {
        ...state,
        activeAsset: {
          asset: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.ASSET_FETCH_FAILURE:
      return {
        ...state,
        activeAsset: {
          asset: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.ASSET_FETCH_SUCCESS:
      return {
        ...state,
        activeAsset: {
          asset: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  ASSET FETCH END  ==================================//
    //================================== ASSETS FETCH START ==================================//
    case ACTIONS.ASSETS_FETCH_START:
      return {
        ...state,
        assetsList: {
          assets: [],
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case ACTIONS.ASSETS_FETCH_SUCCESS:
      return {
        ...state,
        assetsList: {
          ...state.assetsList,
          assets: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case ACTIONS.ASSETS_FETCH_FAILURE:
      return {
        ...state,
        assetsList: {
          ...state.assetsList,
          assets: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case ACTIONS.ASSETS_FETCH_RESET:
      return {
        ...state,
        assetsList: {
          assets: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  ASSETS FETCH END  ==================================//
    default:
      return state;
  }
};
