import { ACTIONS } from '../constants/roles';
import { MODE } from '../constants/mode';

const INITIAL_STATE = {
  rolesList: { roles: [], params: {}, pager: null, error: null, loading: false },
  activeRole: { role: null, error: null, mode: null },
};

export const roles = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.ROLE_CREATE_RESET:
    case ACTIONS.ROLE_UPDATE_RESET:
    case ACTIONS.ROLE_DELETE_RESET:
    case ACTIONS.ROLE_FETCH_RESET:
      return {
        ...state,
        activeRole: {
          role: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //================================== ROLE CREATE START ==================================//
    case ACTIONS.ROLE_CREATE_START:
      return {
        ...state,
        activeRole: {
          role: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.ROLE_CREATE_FAILURE:
      return {
        ...state,
        activeRole: {
          role: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.ROLE_CREATE_SUCCESS:
      return {
        ...state,
        rolesList: {
          ...state.rolesList,
          roles: state.rolesList.roles.concat(action.payload),
          error: null,
          loading: false,
        },
        activeRole: {
          role: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    //==================================  ROLE CREATE END  ==================================//
    //================================== ROLE UPDATE START ==================================//
    case ACTIONS.ROLE_UPDATE_START:
      return {
        ...state,
        activeRole: {
          role: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.ROLE_UPDATE_FAILURE:
      return {
        ...state,
        activeRole: {
          role: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.ROLE_UPDATE_SUCCESS:
      return {
        ...state,
        rolesList: {
          ...state.rolesList,
          roles: state.rolesList.roles.map(perm => {
            if (perm.id === action.payload.id) {
              perm = action.payload;
            }
            return perm;
          }),
          error: null,
          loading: false,
        },
        activeRole: {
          role: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  ROLE UPDATE END  ==================================//
    //================================== ROLE DELETE START ==================================//
    case ACTIONS.ROLE_DELETE_START:
      return {
        ...state,
        activeRole: {
          role: action.payload,
          loading: true,
          error: null,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.ROLE_DELETE_FAILURE:
      return {
        ...state,
        activeRole: {
          role: null,
          loading: false,
          error: action.payload,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.ROLE_DELETE_SUCCESS:
      return {
        ...state,
        rolesList: {
          ...state.rolesList,
          roles: state.rolesList.roles.filter(role => role.id !== action.payload.id),
          error: null,
          loading: false,
        },
        activeRole: {
          role: action.payload,
          loading: false,
          error: null,
          mode: MODE.DELETE,
        },
      };
    //==================================  ROLE DELETE END  ==================================//
    //================================== ROLE FETCH START ==================================//
    case ACTIONS.ROLE_FETCH_START:
      return {
        ...state,
        activeRole: {
          role: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.ROLE_FETCH_FAILURE:
      return {
        ...state,
        activeRole: {
          role: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.ROLE_FETCH_SUCCESS:
      return {
        ...state,
        activeRole: {
          role: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  ROLE FETCH END  ==================================//
    //================================== ROLES FETCH START ==================================//
    case ACTIONS.ROLES_FETCH_START:
      return {
        ...state,
        rolesList: {
          roles: [],
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case ACTIONS.ROLES_FETCH_SUCCESS:
      return {
        ...state,
        rolesList: {
          ...state.rolesList,
          roles: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case ACTIONS.ROLES_FETCH_FAILURE:
      return {
        ...state,
        rolesList: {
          ...state.rolesList,
          roles: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case ACTIONS.ROLES_FETCH_RESET:
      return {
        ...state,
        rolesList: {
          roles: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  ROLES FETCH END  ==================================//
    default:
      return state;
  }
};
