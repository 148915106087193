import { ACTIONS } from '../constants/forgot_password';

const INITIAL_STATE = {
  email: null,
  error: null,
  loading: false,
};

export const forgotPassword = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.FORGOT_PASSWORD_START:
      return {
        ...state,
        email: null,
        error: null,
        loading: true,
      };
    case ACTIONS.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        email: action.payload.email,
        error: null,
        loading: false,
      };
    case ACTIONS.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        email: null,
        error: action.payload,
        loading: false,
      };
    case ACTIONS.RESET_FORGOT_PASSWORD:
      return {
        ...state,
        email: null,
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};
