import { ACTIONS } from '../constants/service-details';
import { MODE } from '../constants/mode';

const INITIAL_STATE = {
  serviceDetailsList: { serviceDetails: [], params: {}, pager: null, error: null, loading: false },
  activeServiceDetail: { serviceDetail: null, error: null, mode: null },
};

export const serviceDetails = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.SERVICE_DETAIL_CREATE_RESET:
    case ACTIONS.SERVICE_DETAIL_UPDATE_RESET:
    case ACTIONS.SERVICE_DETAIL_DELETE_RESET:
    case ACTIONS.SERVICE_DETAIL_FETCH_RESET:
      return {
        ...state,
        activeServiceDetail: {
          serviceDetail: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //================================== SERVICE_DETAIL CREATE START ==================================//
    case ACTIONS.SERVICE_DETAIL_CREATE_START:
      return {
        ...state,
        activeServiceDetail: {
          serviceDetail: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.SERVICE_DETAIL_CREATE_FAILURE:
      return {
        ...state,
        activeServiceDetail: {
          serviceDetail: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.SERVICE_DETAIL_CREATE_SUCCESS:
      return {
        ...state,
        serviceDetailsList: {
          ...state.serviceDetailsList,
          serviceDetails: state.serviceDetailsList.serviceDetails.concat(action.payload),
          error: null,
          loading: false,
        },
        activeServiceDetail: {
          serviceDetail: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    //==================================  SERVICE_DETAIL CREATE END  ==================================//
    //================================== SERVICE_DETAIL UPDATE START ==================================//
    case ACTIONS.SERVICE_DETAIL_UPDATE_START:
      return {
        ...state,
        activeServiceDetail: {
          serviceDetail: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.SERVICE_DETAIL_UPDATE_FAILURE:
      return {
        ...state,
        activeServiceDetail: {
          serviceDetail: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.SERVICE_DETAIL_UPDATE_SUCCESS:
      return {
        ...state,
        serviceDetailsList: {
          ...state.serviceDetailsList,
          serviceDetails: state.serviceDetailsList.serviceDetails.map(perm => {
            if (perm.service_item_id === action.payload.service_item_id) {
              perm = action.payload;
            }
            return perm;
          }),
          error: null,
          loading: false,
        },
        activeServiceDetail: {
          serviceDetail: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  SERVICE_DETAIL UPDATE END  ==================================//
    //================================== SERVICE_DETAIL DELETE START ==================================//
    case ACTIONS.SERVICE_DETAIL_DELETE_START:
      return {
        ...state,
        activeServiceDetail: {
          serviceDetail: action.payload,
          loading: true,
          error: null,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.SERVICE_DETAIL_DELETE_FAILURE:
      return {
        ...state,
        activeServiceDetail: {
          serviceDetail: null,
          loading: false,
          error: action.payload,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.SERVICE_DETAIL_DELETE_SUCCESS:
      return {
        ...state,
        serviceDetailsList: {
          ...state.serviceDetailsList,
          serviceDetails: state.serviceDetailsList.serviceDetails.filter(
            serviceDetail => serviceDetail.service_item_id !== action.payload.service_item_id
          ),
          error: null,
          loading: false,
        },
        activeServiceDetail: {
          serviceDetail: action.payload,
          loading: false,
          error: null,
          mode: MODE.DELETE,
        },
      };
    //==================================  SERVICE_DETAIL DELETE END  ==================================//
    //================================== SERVICE_DETAIL FETCH START ==================================//
    case ACTIONS.SERVICE_DETAIL_FETCH_START:
      return {
        ...state,
        activeServiceDetail: {
          serviceDetail: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.SERVICE_DETAIL_FETCH_FAILURE:
      return {
        ...state,
        activeServiceDetail: {
          serviceDetail: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.SERVICE_DETAIL_FETCH_SUCCESS:
      return {
        ...state,
        activeServiceDetail: {
          serviceDetail: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  SERVICE_DETAIL FETCH END  ==================================//
    //================================== SERVICE_DETAILS FETCH START ==================================//
    case ACTIONS.SERVICE_DETAILS_FETCH_START:
      return {
        ...state,
        serviceDetailsList: {
          serviceDetails: [],
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case ACTIONS.SERVICE_DETAILS_FETCH_SUCCESS:
      return {
        ...state,
        serviceDetailsList: {
          ...state.serviceDetailsList,
          serviceDetails: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case ACTIONS.SERVICE_DETAILS_FETCH_FAILURE:
      return {
        ...state,
        serviceDetailsList: {
          ...state.serviceDetailsList,
          serviceDetails: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case ACTIONS.SERVICE_DETAILS_FETCH_RESET:
      return {
        ...state,
        serviceDetailsList: {
          serviceDetails: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  SERVICE_DETAILS FETCH END  ==================================//
    default:
      return state;
  }
};
