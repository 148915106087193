export const ACTIONS = {
  GET_PROMO_CODES_IN_PROGRESS: 'getPromoCodesInProgress',
  HANDLE_SUCCESS_GET_PROMO_CODES: 'handleSuccessGetPromoCodes',
  HANDLE_ERROR_GET_PROMO_CODES: 'handleErrorGetPromoCodes',
  TOGGLE_PROMO_CODE_MODAL: 'togglePromoCodeModal',
  HANDLE_SUCCESS_ADD_PROMO_CODE: 'handleSuccessAddPromoCode',
  HANDLE_SUCCESS_UPDATE_PROMO_CODE: 'handleSuccessUpdatePromoCode',
  HANDLE_ALTERING_PROMO_CODE_IN_PROGRESS: 'handleAlteringPromoCodeInProgress',
  HANDLE_SUCCESS_DELETE_PROMO_CODE: 'handleSuccessDeletePromoCode',
  HANDLE_SUCCESS_GET_PROMO_CODE: 'handleSuccessGetPromoCode',
  HANDLE_SUCCESS_GET_PROMO_CODE_USERS: 'handleSuccessGetPromoCodeUsers',
};
