export const ACTIONS = {
  TRIAL_GROUPS_FETCH_START: 'TRIAL_GROUPS_FETCH_START',
  TRIAL_GROUPS_FETCH_SUCCESS: 'TRIAL_GROUPS_FETCH_SUCCESS',
  TRIAL_GROUPS_FETCH_FAILURE: 'TRIAL_GROUPS_FETCH_FAILURE',
  TRIAL_GROUPS_FETCH_RESET: 'TRIAL_GROUPS_FETCH_RESET',

  TRIAL_GROUP_FETCH_START: 'TRIAL_GROUP_FETCH_START',
  TRIAL_GROUP_FETCH_SUCCESS: 'TRIAL_GROUP_FETCH_SUCCESS',
  TRIAL_GROUP_FETCH_FAILURE: 'TRIAL_GROUP_FETCH_FAILURE',
  TRIAL_GROUP_FETCH_RESET: 'TRIAL_GROUP_FETCH_RESET',

  TRIAL_GROUP_CREATE_START: 'TRIAL_GROUP_CREATE_START',
  TRIAL_GROUP_CREATE_SUCCESS: 'TRIAL_GROUP_CREATE_SUCCESS',
  TRIAL_GROUP_CREATE_FAILURE: 'TRIAL_GROUP_CREATE_FAILURE',
  TRIAL_GROUP_CREATE_RESET: 'TRIAL_GROUP_CREATE_RESET',

  TRIAL_GROUP_UPDATE_START: 'TRIAL_GROUP_UPDATE_START',
  TRIAL_GROUP_UPDATE_SUCCESS: 'TRIAL_GROUP_UPDATE_SUCCESS',
  TRIAL_GROUP_UPDATE_FAILURE: 'TRIAL_GROUP_UPDATE_FAILURE',
  TRIAL_GROUP_UPDATE_RESET: 'TRIAL_GROUP_UPDATE_RESET',

  TRIAL_GROUP_DELETE_START: 'TRIAL_GROUP_DELETE_START',
  TRIAL_GROUP_DELETE_SUCCESS: 'TRIAL_GROUP_DELETE_SUCCESS',
  TRIAL_GROUP_DELETE_FAILURE: 'TRIAL_GROUP_DELETE_FAILURE',
  TRIAL_GROUP_DELETE_RESET: 'TRIAL_GROUP_DELETE_RESET',

  TRIAL_GROUP_USERS_FETCH_START: 'TRIAL_GROUP_USERS_FETCH_START',
  TRIAL_GROUP_USERS_FETCH_SUCCESS: 'TRIAL_GROUP_USERS_FETCH_SUCCESS',
  TRIAL_GROUP_USERS_FETCH_FAILURE: 'TRIAL_GROUP_USERS_FETCH_FAILURE',
  TRIAL_GROUP_USERS_FETCH_RESET: 'TRIAL_GROUP_USERS_FETCH_RESET',

  TRIAL_GROUP_USERS_INVITE_START: 'TRIAL_GROUP_USERS_INVITE_START',
  TRIAL_GROUP_USERS_INVITE_SUCCESS: 'TRIAL_GROUP_USERS_INVITE_SUCCESS',
  TRIAL_GROUP_USERS_INVITE_FAILURE: 'TRIAL_GROUP_USERS_INVITE_FAILURE',
  TRIAL_GROUP_USERS_INVITE_RESET: 'TRIAL_GROUP_USERS_INVITE_RESET',

  TRIAL_GROUP_USERS_DELETE_START: 'TRIAL_GROUP_USERS_DELETE_START',
  TRIAL_GROUP_USERS_DELETE_SUCCESS: 'TRIAL_GROUP_USERS_DELETE_SUCCESS',
  TRIAL_GROUP_USERS_DELETE_FAILURE: 'TRIAL_GROUP_USERS_DELETE_FAILURE',
  TRIAL_GROUP_USERS_DELETE_RESET: 'TRIAL_GROUP_USERS_DELETE_RESET',
};
