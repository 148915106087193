import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import LaddaButton, { SLIDE_DOWN } from 'react-ladda';

import { toggleConfirmModal } from '../../actions/common';

class ConfirmModal extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  };

  handleConfirmClick() {
    const { onConfirm } = this.props;

    if (onConfirm && typeof onConfirm === 'function') {
      onConfirm();
    }
  }

  render() {
    const { t } = this.context;
    const { showConfirmModal, title, message, toggleConfirmModal } = this.props;

    return (
      <Modal size="sm" centered={true} isOpen={showConfirmModal} toggle={this.toggleModal}>
        <ModalHeader toggle={this.toggleModal}>{t(title)}</ModalHeader>
        <ModalBody>
          <h5 className="font-weight-bold">{t(message)}</h5>
          <Row>
            <Col sm="6">
              <LaddaButton
                className="btn btn-primary btn-ladda col-sm-12"
                data-color="blue"
                data-style={SLIDE_DOWN}
                onClick={() => toggleConfirmModal(false, '', '', null)}
              >
                {t('APP.NO')}
              </LaddaButton>
            </Col>
            <Col sm="6">
              <LaddaButton
                className="btn btn-danger btn-ladda col-sm-12"
                data-color="blue"
                data-style={SLIDE_DOWN}
                onClick={() => this.handleConfirmClick()}
              >
                {t('APP.YES')}
              </LaddaButton>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  showConfirmModal: state.common.showConfirmModal,
  title: state.common.confirmModalTitle,
  message: state.common.confirmModalMessage,
  onConfirm: state.common.confirmModalOnConfirm,
});

const mapDispatchToProps = dispatch => ({
  toggleConfirmModal: (show, title, message, onSubmit) => {
    dispatch(toggleConfirmModal(show, title, message, onSubmit));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal);
