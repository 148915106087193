import { ACTIONS } from '../constants/users';
import { MODE } from '../constants/mode';

const INITIAL_STATE = {
  userList: { users: [], params: {}, pager: null, error: null, loading: false },
  buddyList: { users: [], params: {}, pager: null, error: null, loading: false },
  activeUser: { user: null, error: null, mode: null, loading: false },
  userRoles: { roles: [], params: {}, pager: null, error: null, loading: false },
  userBicycles: { bicycles: [], params: {}, pager: null, error: null, loading: false },
  userCompetencies: { competencies: [], params: {}, pager: null, error: null, loading: false },
  userLanguages: { languages: [], params: {}, pager: null, error: null, loading: false },
  userOperationalRange: { operationalRange: null, error: null, mode: null, loading: false },
  servicesList: { services: [], params: {}, pager: null, error: null, loading: false },
};

export const users = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.USER_CREATE_RESET:
    case ACTIONS.USER_UPDATE_RESET:
    case ACTIONS.USER_DELETE_RESET:
    case ACTIONS.USER_FETCH_RESET:
      return {
        ...state,
        activeUser: {
          user: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //================================== USER CREATE START ==================================//
    case ACTIONS.USER_CREATE_START:
      return {
        ...state,
        activeUser: {
          user: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.USER_CREATE_FAILURE:
      return {
        ...state,
        activeUser: {
          user: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.USER_CREATE_SUCCESS:
      return {
        ...state,
        userList: {
          users: state.userList.users.concat(action.payload),
          error: null,
          loading: false,
        },
        activeUser: {
          user: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    //==================================  USER CREATE END  ==================================//
    //================================== USER UPDATE START ==================================//
    case ACTIONS.USER_UPDATE_START:
      return {
        ...state,
        activeUser: {
          user: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.USER_UPDATE_FAILURE:
      return {
        ...state,
        activeUser: {
          user: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.USER_UPDATE_SUCCESS:
      return {
        ...state,
        userList: {
          users: state.userList.users.map(perm => {
            if (perm.id === action.payload.id) {
              perm = action.payload;
            }
            return perm;
          }),
          error: null,
          loading: false,
        },
        activeUser: {
          user: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  USER UPDATE END  ==================================//
    //================================== USER DELETE START ==================================//
    case ACTIONS.USER_DELETE_START:
      return {
        ...state,
        activeUser: {
          user: action.payload,
          loading: true,
          error: null,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.USER_DELETE_FAILURE:
      return {
        ...state,
        activeUser: {
          user: null,
          loading: false,
          error: action.payload,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.USER_DELETE_SUCCESS:
      return {
        ...state,
        userList: {
          users: state.userList.users.filter(perm => perm.id !== action.payload.id),
          error: null,
          loading: false,
        },
        activeUser: {
          user: action.payload,
          loading: false,
          error: null,
          mode: MODE.DELETE,
        },
      };
    //==================================  USER DELETE END  ==================================//
    //================================== USER FETCH START ==================================//
    case ACTIONS.USER_FETCH_START:
      return {
        ...state,
        activeUser: {
          user: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.USER_FETCH_FAILURE:
      return {
        ...state,
        activeUser: {
          user: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.USER_FETCH_SUCCESS:
      return {
        ...state,
        activeUser: {
          user: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  USER FETCH END   ==================================//
    //================================== USERS FETCH START ==================================//
    case ACTIONS.USERS_FETCH_START:
      return {
        ...state,
        userList: {
          users: [],
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case ACTIONS.USERS_FETCH_SUCCESS:
      return {
        ...state,
        userList: {
          ...state.userList,
          users: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case ACTIONS.USERS_FETCH_FAILURE:
      return {
        ...state,
        userList: {
          ...state.userList,
          users: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case ACTIONS.USERS_FETCH_RESET:
      return {
        ...state,
        userList: {
          users: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  USERS FETCH END  ==================================//
    //================================== USER INVITE START ==================================//
    case ACTIONS.USER_INVITE_START:
      return {
        ...state,
        activeUser: {
          user: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.USER_INVITE_FAILURE:
      return {
        ...state,
        activeUser: {
          user: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.USER_INVITE_SUCCESS:
      return {
        ...state,
        activeUser: {
          user: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    case ACTIONS.USER_BULK_INVITE_START:
      return {
        ...state,
      };
    case ACTIONS.USER_BULK_INVITE_FAILURE:
      return {
        ...state,
        activeUser: {
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.USER_BULK_INVITE_SUCCESS:
      return {
        ...state,
        activeUser: {
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    //==================================  USER INVITE END  ==================================//
    //===========================  USER ROLES FETCH START  ==================================//
    case ACTIONS.USER_ROLES_FETCH_START:
      return {
        ...state,
        userRoles: {
          roles: [],
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case ACTIONS.USER_ROLES_FETCH_SUCCESS:
      return {
        ...state,
        userRoles: {
          ...state.userRoles,
          roles: action.payload.results,
          pager: action.payload.paginator,
          loading: false,
          error: null,
        },
      };
    case ACTIONS.USER_ROLES_FETCH_FAILURE:
      return {
        ...state,
        userRoles: {
          ...state.userRoles,
          roles: [],
          pager: null,
          loading: false,
          error: action.payload,
        },
      };
    case ACTIONS.USER_ROLES_FETCH_RESET:
      return {
        ...state,
        userRoles: {
          roles: [],
          loading: false,
          error: false,
        },
      };
    //===========================   USER ROLES FETCH END   ==================================//
    //===========================  USER ROLE ADD START  ==================================//
    case ACTIONS.USER_ROLE_ADD_START:
      return state; // do nothing here
    case ACTIONS.USER_ROLE_ADD_SUCCESS:
      return {
        ...state,
        userRoles: {
          roles: state.userRoles.roles.concat(action.payload),
          loading: false,
          error: null,
        },
      };
    case ACTIONS.USER_ROLE_ADD_FAILURE:
      return {
        ...state,
        userRoles: {
          ...state.userRoles, // add error message to existing userRoles state
          error: action.payload,
        },
      };
    case ACTIONS.USER_ROLE_ADD_RESET:
      return state; // do nothing
    //===========================   USER ROLE ADD END   ==================================//
    //===========================  USER ROLE REMOVE START  ==================================//
    case ACTIONS.USER_ROLE_REMOVE_START:
      return state; // do nothing here
    case ACTIONS.USER_ROLE_REMOVE_SUCCESS:
      return {
        ...state,
        userRoles: {
          roles: state.userRoles.roles.filter(r => r.id !== action.payload.id),
          loading: false,
          error: null,
        },
      };
    case ACTIONS.USER_ROLE_REMOVE_FAILURE:
      return {
        ...state,
        userRoles: {
          ...state.userRoles, // add error message to existing userRoles state
          error: action.payload,
        },
      };
    case ACTIONS.USER_ROLE_REMOVE_RESET:
      return state; // do nothing
    //===========================   USER ROLE REMOVE END   ==================================//
    //===========================  USER BICYCLES FETCH START  ==================================//
    case ACTIONS.USER_BICYCLES_FETCH_START:
      return {
        ...state,
        userBicycles: {
          bicycles: [],
          params: action.payload,
          pager: null,
          // error: null,
          loading: true,
        },
      };
    case ACTIONS.USER_BICYCLES_FETCH_SUCCESS:
      return {
        ...state,
        userBicycles: {
          ...state.userBicycles,
          bicycles: action.payload.results,
          pager: action.payload.paginator,
          loading: false,
          // error: null,
        },
      };
    case ACTIONS.USER_BICYCLES_FETCH_FAILURE:
      return {
        ...state,
        userBicycles: {
          ...state.userBicycles,
          bicycles: [],
          pager: null,
          loading: false,
          error: action.payload,
        },
      };
    case ACTIONS.USER_BICYCLES_FETCH_RESET:
      return {
        ...state,
        userBicycles: {
          bicycles: [],
          loading: false,
          error: false,
        },
      };
    //===========================   USER BICYCLES FETCH END   ==================================//

    //===========================  USER BICYCLES UPDATE START  ==================================//
    case ACTIONS.USER_BICYCLES_UPDATE_SUCCESS:
      return {
        ...state,
        userBicycles: state.userBicycles,
      };
    case ACTIONS.USER_BICYCLES_UPDATE_FAILURE:
      return {
        ...state,
        userBicycles: {
          ...state.userBicycles,
          bicycles: state.userBicycles.bicycles,
          pager: null,
          loading: false,
          error: action.payload,
        },
      };
    //===========================  USER BICYCLES UPDATE START  ==================================//

    //===========================  USER COMPETENCIES FETCH START  ==================================//
    case ACTIONS.USER_COMPETENCIES_FETCH_START:
      return {
        ...state,
        userCompetencies: {
          competencies: [],
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case ACTIONS.USER_COMPETENCIES_FETCH_SUCCESS:
      return {
        ...state,
        userCompetencies: {
          ...state.userCompetencies,
          competencies: action.payload.results,
          pager: action.payload.paginator,
          loading: false,
          error: null,
        },
      };
    case ACTIONS.USER_COMPETENCIES_FETCH_FAILURE:
      return {
        ...state,
        userCompetencies: {
          ...state.userCompetencies,
          bicycles: [],
          pager: null,
          loading: false,
          error: action.payload,
        },
      };
    case ACTIONS.USER_COMPETENCIES_FETCH_RESET:
      return {
        ...state,
        userCompetencies: {
          competencies: [],
          loading: false,
          error: false,
          pager: null,
          params: {},
        },
      };
    //===========================   USER COMPETENCIES FETCH END   ==================================//
    //===========================  USER COMPETENCE ADD START  ==================================//
    case ACTIONS.USER_COMPETENCE_ADD_START:
      return state; // do nothing here
    case ACTIONS.USER_COMPETENCE_ADD_SUCCESS:
      return {
        ...state,
        userCompetencies: {
          competencies: state.userCompetencies.competencies.concat(action.payload),
          loading: false,
          error: null,
        },
      };
    case ACTIONS.USER_COMPETENCE_ADD_FAILURE:
      return {
        ...state,
        userCompetencies: {
          ...state.userCompetencies, // add error message to existing userRoles state
          error: action.payload,
        },
      };
    case ACTIONS.USER_COMPETENCE_ADD_RESET:
      return state; // do nothing
    //===========================   USER COMPETENCE ADD END   ==================================//
    //===========================  USER COMPETENCE REMOVE START  ==================================//
    case ACTIONS.USER_COMPETENCE_REMOVE_START:
      return state; // do nothing here
    case ACTIONS.USER_COMPETENCE_REMOVE_SUCCESS:
      return {
        ...state,
        userCompetencies: {
          competencies: state.userCompetencies.competencies.filter(r => r.id !== action.payload.id),
          loading: false,
          error: null,
        },
      };
    case ACTIONS.USER_COMPETENCE_REMOVE_FAILURE:
      return {
        ...state,
        userCompetencies: {
          ...state.userCompetencies, // add error message to existing userRoles state
          error: action.payload,
        },
      };
    case ACTIONS.USER_COMPETENCE_REMOVE_RESET:
      return state; // do nothing
    //===========================  USER LANGUAGES FETCH START  ==================================//
    case ACTIONS.USER_LANGUAGES_FETCH_START:
      return {
        ...state,
        userLanguages: {
          languages: [],
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case ACTIONS.USER_LANGUAGES_FETCH_SUCCESS:
      return {
        ...state,
        userLanguages: {
          ...state.userLanguages,
          languages: action.payload.results,
          pager: action.payload.paginator,
          loading: false,
          error: null,
        },
      };
    case ACTIONS.USER_LANGUAGES_FETCH_FAILURE:
      return {
        ...state,
        userLanguages: {
          ...state.userLanguages,
          languages: [],
          pager: null,
          loading: false,
          error: action.payload,
        },
      };
    case ACTIONS.USER_LANGUAGES_FETCH_RESET:
      return {
        ...state,
        userLanguages: {
          languages: [],
          loading: false,
          error: false,
        },
      };
    //===========================   USER LANGUAGES FETCH END   ==================================//
    //===========================  USER LANGUAGE ADD START  ==================================//
    case ACTIONS.USER_LANGUAGE_ADD_START:
      return state; // do nothing here
    case ACTIONS.USER_LANGUAGE_ADD_SUCCESS:
      return {
        ...state,
        userLanguages: {
          languages: state.userLanguages.languages.concat(action.payload),
          loading: false,
          error: null,
        },
      };
    case ACTIONS.USER_LANGUAGE_ADD_FAILURE:
      return {
        ...state,
        userLanguages: {
          ...state.userLanguages, // add error message to existing userLanguages state
          error: action.payload,
        },
      };
    case ACTIONS.USER_LANGUAGE_ADD_RESET:
      return state; // do nothing
    //===========================   USER LANGUAGE ADD END   ==================================//
    //===========================  USER LANGUAGE REMOVE START  ==================================//
    case ACTIONS.USER_LANGUAGE_REMOVE_START:
      return state; // do nothing here
    case ACTIONS.USER_LANGUAGE_REMOVE_SUCCESS:
      return {
        ...state,
        userLanguages: {
          languages: state.userLanguages.languages.filter(r => r.id !== action.payload.id),
          loading: false,
          error: null,
        },
      };
    case ACTIONS.USER_LANGUAGE_REMOVE_FAILURE:
      return {
        ...state,
        userLanguages: {
          ...state.userLanguages, // add error message to existing userLanguages state
          error: action.payload,
        },
      };
    case ACTIONS.USER_LANGUAGE_REMOVE_RESET:
      return state; // do nothing
    //===========================   USER LANGUAGE REMOVE END   ==================================//
    //===========================   USER COMPETENCE REMOVE END   ==================================//
    //===========================  USER OPERATIONAL RANGE FETCH START  ==================================//
    case ACTIONS.USER_OPERATIONAL_RANGE_FETCH_START:
      return {
        ...state,
        userOperationalRange: {
          operationalRange: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.USER_OPERATIONAL_RANGE_FETCH_SUCCESS:
      return {
        ...state,
        userOperationalRange: {
          operationalRange: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.USER_OPERATIONAL_RANGE_FETCH_FAILURE:
      return {
        ...state,
        userOperationalRange: {
          operationalRange: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.USER_OPERATIONAL_RANGE_FETCH_RESET:
      return {
        ...state,
        userOperationalRange: {
          operationalRange: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //================================== SERVICES FETCH START ==================================//
    case ACTIONS.USER_SERVICES_FETCH_START:
      return {
        ...state,
        servicesList: {
          services: [],
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case ACTIONS.USER_SERVICES_FETCH_SUCCESS:
      return {
        ...state,
        servicesList: {
          ...state.servicesList,
          services: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case ACTIONS.USER_SERVICES_FETCH_FAILURE:
      return {
        ...state,
        servicesList: {
          ...state.servicesList,
          services: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case ACTIONS.USER_SERVICES_FETCH_RESET:
      return {
        ...state,
        servicesList: {
          services: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //===========================   USER OPERATIONAL RANGE FETCH END   ==================================//
    //================================== BUDDIES FETCH START ==================================//
    case ACTIONS.BUDDIES_FETCH_START:
      return {
        ...state,
        buddyList: {
          users: [],
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case ACTIONS.BUDDIES_FETCH_SUCCESS:
      return {
        ...state,
        buddyList: {
          ...state.userList,
          users: action.payload.results.filter(u => u.is_active),
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case ACTIONS.BUDDIES_FETCH_FAILURE:
      return {
        ...state,
        buddyList: {
          ...state.userList,
          users: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case ACTIONS.BUDDIES_FETCH_RESET:
      return {
        ...state,
        buddyList: {
          users: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    default:
      return state;
  }
};
