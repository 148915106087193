export const ACTIONS = {
  PRODUCT_FETCH_START: 'PRODUCT_FETCH_START',
  PRODUCT_FETCH_SUCCESS: 'PRODUCT_FETCH_SUCCESS',
  PRODUCT_FETCH_FAILURE: 'PRODUCT_FETCH_FAILURE',
  PRODUCT_FETCH_RESET: 'PRODUCT_FETCH_RESET',

  PRODUCTS_FETCH_START: 'PRODUCTS_FETCH_START',
  PRODUCTS_FETCH_SUCCESS: 'PRODUCTS_FETCH_SUCCESS',
  PRODUCTS_FETCH_FAILURE: 'PRODUCTS_FETCH_FAILURE',
  PRODUCTS_FETCH_RESET: 'PRODUCTS_FETCH_RESET',

  PRODUCT_CREATE_START: 'PRODUCT_CREATE_START',
  PRODUCT_CREATE_SUCCESS: 'PRODUCT_CREATE_SUCCESS',
  PRODUCT_CREATE_FAILURE: 'PRODUCT_CREATE_FAILURE',
  PRODUCT_CREATE_RESET: 'PRODUCT_CREATE_RESET',

  PRODUCT_UPDATE_START: 'PRODUCT_UPDATE_START',
  PRODUCT_UPDATE_SUCCESS: 'PRODUCT_UPDATE_SUCCESS',
  PRODUCT_UPDATE_FAILURE: 'PRODUCT_UPDATE_FAILURE',
  PRODUCT_UPDATE_RESET: 'PRODUCT_UPDATE_RESET',

  PRODUCT_DELETE_START: 'PRODUCT_DELETE_START',
  PRODUCT_DELETE_SUCCESS: 'PRODUCT_DELETE_SUCCESS',
  PRODUCT_DELETE_FAILURE: 'PRODUCT_DELETE_FAILURE',
  PRODUCT_DELETE_RESET: 'PRODUCT_DELETE_RESET',
};
