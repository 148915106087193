import axios from 'axios';

import { objectToQueryParams } from '../helpers/url';

class CommonAPI {
  static generateUploadURL(params) {
    const query = objectToQueryParams(params);
    return axios({
      method: 'GET',
      url: `upload/?${query}`,
      isExternalRequest: true,
    });
  }

  static uploadFile(file, url) {
    return axios({
      method: 'PUT',
      url,
      data: file,
      headers: {
        'Content-Type': file.type,
        'x-goog-acl': 'public-read',
      },
      isExternalRequest: true,
    });
  }
}

export default CommonAPI;
