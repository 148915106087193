import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import I18n from 'redux-i18n';

import config from './config/config';
import initilizeHttpClient from './config/axios-config';
import ShellContainer from './containers/shell-container';
import Store from './store';
import { translations } from './translations';
import routes from './routes';
import * as serviceWorker from './serviceWorker';

const StoreInstance = Store();

const lang = localStorage.getItem('lang') || config.defaultLanguage;

initilizeHttpClient();

ReactDOM.render(
  <Provider store={StoreInstance}>
    <I18n translations={translations} initialLang={lang} fallbackLang="en">
      <BrowserRouter>
        <ShellContainer routes={routes} />
      </BrowserRouter>
    </I18n>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

export default StoreInstance;
