export const ACTIONS = {
  BIKE_BUDDY_SCHEDULER_SLOTS_FETCH_START: 'BIKE_BUDDY_SCHEDULER_SLOTS_FETCH_START',
  BIKE_BUDDY_SCHEDULER_SLOTS_FETCH_SUCCESS: 'BIKE_BUDDY_SCHEDULER_SLOTS_FETCH_SUCCESS',
  BIKE_BUDDY_SCHEDULER_SLOTS_FETCH_FAILURE: 'BIKE_BUDDY_SCHEDULER_SLOTS_FETCH_FAILURE',
  BIKE_BUDDY_SCHEDULER_SLOTS_FETCH_RESET: 'BIKE_BUDDY_SCHEDULER_SLOTS_FETCH_RESET',

  BIKE_BUDDY_SCHEDULER_SLOT_FETCH_START: 'BIKE_BUDDY_SCHEDULER_SLOT_FETCH_START',
  BIKE_BUDDY_SCHEDULER_SLOT_FETCH_SUCCESS: 'BIKE_BUDDY_SCHEDULER_SLOT_FETCH_SUCCESS',
  BIKE_BUDDY_SCHEDULER_SLOT_FETCH_FAILURE: 'BIKE_BUDDY_SCHEDULER_SLOT_FETCH_FAILURE',
  BIKE_BUDDY_SCHEDULER_SLOT_FETCH_RESET: 'BIKE_BUDDY_SCHEDULER_SLOT_FETCH_RESET',

  BIKE_BUDDY_SCHEDULER_SLOT_CREATE_START: 'BIKE_BUDDY_SCHEDULER_SLOT_CREATE_START',
  BIKE_BUDDY_SCHEDULER_SLOT_CREATE_SUCCESS: 'BIKE_BUDDY_SCHEDULER_SLOT_CREATE_SUCCESS',
  BIKE_BUDDY_SCHEDULER_SLOT_CREATE_OFF_SUCCESS: 'BIKE_BUDDY_SCHEDULER_SLOT_CREATE_OFF_SUCCESS',
  BIKE_BUDDY_SCHEDULER_SLOT_CREATE_FAILURE: 'BIKE_BUDDY_SCHEDULER_SLOT_CREATE_FAILURE',
  BIKE_BUDDY_SCHEDULER_SLOT_CREATE_RESET: 'BIKE_BUDDY_SCHEDULER_SLOT_CREATE_RESET',

  BIKE_BUDDY_SCHEDULER_SLOT_UPDATE_START: 'BIKE_BUDDY_SCHEDULER_SLOT_UPDATE_START',
  BIKE_BUDDY_SCHEDULER_SLOT_UPDATE_SUCCESS: 'BIKE_BUDDY_SCHEDULER_SLOT_UPDATE_SUCCESS',
  BIKE_BUDDY_SCHEDULER_SLOT_UPDATE_FAILURE: 'BIKE_BUDDY_SCHEDULER_SLOT_UPDATE_FAILURE',
  BIKE_BUDDY_SCHEDULER_SLOT_UPDATE_RESET: 'BIKE_BUDDY_SCHEDULER_SLOT_UPDATE_RESET',

  BIKE_BUDDY_SCHEDULER_SLOT_DELETE_START: 'BIKE_BUDDY_SCHEDULER_SLOT_DELETE_START',
  BIKE_BUDDY_SCHEDULER_SLOT_DELETE_SUCCESS: 'BIKE_BUDDY_SCHEDULER_SLOT_DELETE_SUCCESS',
  BIKE_BUDDY_SCHEDULER_SLOT_DELETE_FAILURE: 'BIKE_BUDDY_SCHEDULER_SLOT_DELETE_FAILURE',
  BIKE_BUDDY_SCHEDULER_SLOT_DELETE_RESET: 'BIKE_BUDDY_SCHEDULER_SLOT_DELETE_RESET',
};
