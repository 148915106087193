export const ACTIONS = {
  COMPETENCIES_FETCH_START: 'COMPETENCIES_FETCH_START',
  COMPETENCIES_FETCH_SUCCESS: 'COMPETENCIES_FETCH_SUCCESS',
  COMPETENCIES_FETCH_FAILURE: 'COMPETENCIES_FETCH_FAILURE',
  COMPETENCIES_FETCH_RESET: 'COMPETENCIES_FETCH_RESET',

  COMPETENCE_FETCH_START: 'COMPETENCE_FETCH_START',
  COMPETENCE_FETCH_SUCCESS: 'COMPETENCE_FETCH_SUCCESS',
  COMPETENCE_FETCH_FAILURE: 'COMPETENCE_FETCH_FAILURE',
  COMPETENCE_FETCH_RESET: 'COMPETENCE_FETCH_RESET',

  COMPETENCE_CREATE_START: 'COMPETENCE_CREATE_START',
  COMPETENCE_CREATE_SUCCESS: 'COMPETENCE_CREATE_SUCCESS',
  COMPETENCE_CREATE_FAILURE: 'COMPETENCE_CREATE_FAILURE',
  COMPETENCE_CREATE_RESET: 'COMPETENCE_CREATE_RESET',

  COMPETENCE_UPDATE_START: 'COMPETENCE_UPDATE_START',
  COMPETENCE_UPDATE_SUCCESS: 'COMPETENCE_UPDATE_SUCCESS',
  COMPETENCE_UPDATE_FAILURE: 'COMPETENCE_UPDATE_FAILURE',
  COMPETENCE_UPDATE_RESET: 'COMPETENCE_UPDATE_RESET',

  COMPETENCE_DELETE_START: 'COMPETENCE_DELETE_START',
  COMPETENCE_DELETE_SUCCESS: 'COMPETENCE_DELETE_SUCCESS',
  COMPETENCE_DELETE_FAILURE: 'COMPETENCE_DELETE_FAILURE',
  COMPETENCE_DELETE_RESET: 'COMPETENCE_DELETE_RESET',
};
