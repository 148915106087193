import { ACTIONS } from '../constants/export-data';

const INITIAL_STATE = {
  bicycleList: { bicycles: [], params: {}, pager: null, error: null, loading: false },
};

export const exportData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //================================== EXPORT DATA BICYCLES FETCH START ==================================//
    case ACTIONS.EXPORT_DATA_BICYCLES_FETCH_START:
      return {
        ...state,
        bicycleList: {
          bicycles: [],
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case ACTIONS.EXPORT_DATA_BICYCLES_FETCH_SUCCESS:
      return {
        ...state,
        bicycleList: {
          ...state.bicycleList,
          bicycles: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case ACTIONS.EXPORT_DATA_BICYCLES_FETCH_FAILURE:
      return {
        ...state,
        bicycleList: {
          ...state.bicycleList,
          bicycles: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case ACTIONS.EXPORT_DATA_BICYCLES_FETCH_RESET:
      return {
        ...state,
        bicycleList: {
          bicycles: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  EXPORT DATA BICYCLES FETCH END  ==================================//
    default:
      return state;
  }
};
