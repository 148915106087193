import { ACTIONS } from '../constants/confirm_email';

const INITIAL_STATE = {
  success: false,
  error: null,
  loading: false,
};

export const confirmEmail = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.CONFIRM_EMAIL_START:
      return {
        ...state,
        success: false,
        error: null,
        loading: true,
      };
    case ACTIONS.CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        success: action.payload,
        error: null,
        loading: false,
      };
    case ACTIONS.CONFIRM_EMAIL_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
        loading: false,
      };
    case ACTIONS.CONFIRM_EMAIL_RESET:
      return {
        ...state,
        success: false,
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};
