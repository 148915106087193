export const ACTIONS = {
  HANDLE_SUCCESS_GET_SERVICE_SLOT_UNREACHABLES: 'handleSuccessGetServiceSlotUnreachables',
  HANDLE_LOADING_SERVICE_SLOT_UNREACHABLES: 'handleLoadingServiceSlotUnreachables',
  HANDLE_ERROR_GET_SERVICE_SLOT_UNREACHABLES: 'handleErrorGetServiceSlotUnreachables',
  TOGGLE_SERVICE_SLOT_UNREACHABLE_MODAL: 'toggleServiceSlotUnreachableModal',
  HANDLE_ALTER_SERVICE_SLOT_UNREACHABLE_IN_PROGRESS: 'handleAlterServiceSlotUnreachableInProgress',
  HANDLE_SUCCESS_ADD_SERVICE_SLOT_UNREACHABLE: 'handleSuccessAddServiceSlotUnreachable',
  HANDLE_SUCCESS_UPDATE_SERVICE_SLOT_UNREACHABLE: 'handleSuccessUpdateServiceSlotUnreachable',
  HANDLE_SUCCESS_DELETE_SERVICE_SLOT_UNREACHABLE: 'handleSuccessDeleteServiceSlotUnreachable',
};
