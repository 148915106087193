import { ACTIONS } from '../constants/auth';
import AuthAPI from '../api/auth';
import AccountAPI from '../api/account';
import {
  setActionCreatorStart,
  setActionCreatorSuccess,
  setActionCreatorFailure,
} from '../actions/common';

import { profileFetch } from './profile';

export const login = (email, password) => {
  return dispatch => {
    dispatch(setActionCreatorStart(ACTIONS.LOGIN_START));
    return AccountAPI.login(email, password)
      .then(response => {
        if (response.data) {
          if (response.data.success) {
            dispatch(setActionCreatorSuccess(response.data.data, ACTIONS.LOGIN_SUCCESS));
            dispatch(profileFetch());
          } else {
            dispatch(loginFailure(new Error(response.data.error)));
          }
        } else {
          dispatch(loginFailure(new Error('Unexpected server response')));
        }
      })
      .catch(error => {
        dispatch(loginFailure(error));
      });
  };
};

export const addClaims = claims => {
  return dispatch => {
    dispatch(setActionCreatorSuccess(claims, ACTIONS.ADD_CLAIMS));
  };
};

export const googleLogin = data => {
  return dispatch => {
    dispatch(setActionCreatorStart(ACTIONS.LOGIN_START));
    return AuthAPI.googleLogin(data)
      .then(response => {
        if (response.data) {
          if (response.data.success) {
            dispatch(setActionCreatorSuccess(response.data.data, ACTIONS.LOGIN_SUCCESS));
          } else {
            dispatch(loginFailure(new Error(response.data.error)));
          }
        } else {
          dispatch(loginFailure(new Error('Unexpected server response')));
        }
      })
      .catch(error => {
        dispatch(loginFailure(error));
      });
  };
};

// loginFailure is not replaced with setActionCreatorFailure as it is reused in multiple places
// TODO We can replace it in places where used, with setActionCreatorFailure in future
export const loginFailure = error => {
  return setActionCreatorFailure(error, ACTIONS.LOGIN_FAILURE);
};

export const resetLogin = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');

  return {
    type: ACTIONS.RESET_LOGIN,
  };
};
