import { ACTIONS } from '../constants/trial-groups';
import { MODE } from '../constants/mode';

const INITIAL_STATE = {
  trialGroupsList: { trialGroups: [], params: {}, pager: null, error: null, loading: false },
  activeTrialGroup: { trialGroup: null, error: null, mode: null },
  trialGroupUserList: { users: [], params: {}, pager: null, error: null, loading: false },
  invite: { params: {}, error: null, loading: false, mode: MODE.VIEW },
};

export const trialGroups = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.TRIAL_GROUP_CREATE_RESET:
    case ACTIONS.TRIAL_GROUP_UPDATE_RESET:
    case ACTIONS.TRIAL_GROUP_DELETE_RESET:
    case ACTIONS.TRIAL_GROUP_FETCH_RESET:
      return {
        ...state,
        activeTrialGroup: {
          trialGroup: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //================================== TRIAL_GROUP CREATE START ==================================//
    case ACTIONS.TRIAL_GROUP_CREATE_START:
      return {
        ...state,
        activeTrialGroup: {
          trialGroup: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.TRIAL_GROUP_CREATE_FAILURE:
      return {
        ...state,
        activeTrialGroup: {
          trialGroup: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.TRIAL_GROUP_CREATE_SUCCESS:
      return {
        ...state,
        trialGroupsList: {
          ...state.trialGroupsList,
          trialGroups: state.trialGroupsList.trialGroups.concat(action.payload),
          error: null,
          loading: false,
        },
        activeTrialGroup: {
          trialGroup: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    //==================================  TRIAL_GROUP CREATE END  ==================================//
    //================================== TRIAL_GROUP UPDATE START ==================================//
    case ACTIONS.TRIAL_GROUP_UPDATE_START:
      return {
        ...state,
        activeTrialGroup: {
          trialGroup: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.TRIAL_GROUP_UPDATE_FAILURE:
      return {
        ...state,
        activeTrialGroup: {
          trialGroup: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.TRIAL_GROUP_UPDATE_SUCCESS:
      return {
        ...state,
        trialGroupsList: {
          ...state.trialGroupsList,
          trialGroups: state.trialGroupsList.trialGroups.map(perm => {
            if (perm.id === action.payload.id) {
              perm = action.payload;
            }
            return perm;
          }),
          error: null,
          loading: false,
        },
        activeTrialGroup: {
          trialGroup: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  TRIAL_GROUP UPDATE END  ==================================//
    //================================== TRIAL_GROUP DELETE START ==================================//
    case ACTIONS.TRIAL_GROUP_DELETE_START:
      return {
        ...state,
        activeTrialGroup: {
          trialGroup: action.payload,
          loading: true,
          error: null,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.TRIAL_GROUP_DELETE_FAILURE:
      return {
        ...state,
        activeTrialGroup: {
          trialGroup: null,
          loading: false,
          error: action.payload,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.TRIAL_GROUP_DELETE_SUCCESS:
      return {
        ...state,
        trialGroupsList: {
          ...state.trialGroupsList,
          trialGroups: state.trialGroupsList.trialGroups.filter(
            trialGroup => trialGroup.id !== action.payload.id
          ),
          error: null,
          loading: false,
        },
        activeTrialGroup: {
          trialGroup: action.payload,
          loading: false,
          error: null,
          mode: MODE.DELETE,
        },
      };
    //==================================  TRIAL_GROUP DELETE END  ==================================//
    //================================== TRIAL_GROUP FETCH START ==================================//
    case ACTIONS.TRIAL_GROUP_FETCH_START:
      return {
        ...state,
        activeTrialGroup: {
          trialGroup: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.TRIAL_GROUP_FETCH_FAILURE:
      return {
        ...state,
        activeTrialGroup: {
          trialGroup: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.TRIAL_GROUP_FETCH_SUCCESS:
      return {
        ...state,
        activeTrialGroup: {
          trialGroup: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  TRIAL_GROUP FETCH END  ==================================//
    //================================== TRIAL_GROUPS FETCH START ==================================//
    case ACTIONS.TRIAL_GROUPS_FETCH_START:
      return {
        ...state,
        trialGroupsList: {
          trialGroups: [],
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case ACTIONS.TRIAL_GROUPS_FETCH_SUCCESS:
      return {
        ...state,
        trialGroupsList: {
          ...state.trialGroupsList,
          trialGroups: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case ACTIONS.TRIAL_GROUPS_FETCH_FAILURE:
      return {
        ...state,
        trialGroupsList: {
          ...state.trialGroupsList,
          trialGroups: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case ACTIONS.TRIAL_GROUPS_FETCH_RESET:
      return {
        ...state,
        trialGroupsList: {
          trialGroups: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  TRIAL_GROUPS FETCH END  ==================================//
    //================================== TRIAL_GROUP_USERS FETCH START ==================================//
    case ACTIONS.TRIAL_GROUP_USERS_FETCH_START:
      return {
        ...state,
        trialGroupUserList: {
          ...state.trialGroupUserList,
          params: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.TRIAL_GROUP_USERS_FETCH_FAILURE:
      return {
        ...state,
        trialGroupUserList: {
          ...state.trialGroupUserList,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.TRIAL_GROUP_USERS_FETCH_SUCCESS:
      return {
        ...state,
        invite: { params: {}, error: null, loading: false, mode: MODE.VIEW },
        trialGroupUserList: {
          users: action.payload.results,
          pager: action.payload.paginator,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  TRIAL_GROUP_USERS FETCH END  ==================================//
    //================================== TRIAL_GROUP_USERS INVITE START ==================================//
    case ACTIONS.TRIAL_GROUP_USERS_INVITE_START:
      return {
        ...state,
        invite: {
          params: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.TRIAL_GROUP_USERS_INVITE_FAILURE:
      return {
        ...state,
        invite: {
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.TRIAL_GROUP_USERS_INVITE_SUCCESS:
      return {
        ...state,
        invite: {
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };
    //==================================  TRIAL_GROUP_USERS FETCH END  ==================================//
    default:
      return state;
  }
};
