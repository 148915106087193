import { ACTIONS } from '../constants/service_items';
import { MODE } from '../constants/mode';

const INITIAL_STATE = {
  serviceItemsList: { serviceItems: [], params: {}, pager: null, error: null, loading: false },
  activeServiceItem: { serviceItem: null, error: null, mode: null },
};

export const serviceItems = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.SERVICE_ITEM_CREATE_RESET:
    case ACTIONS.SERVICE_ITEM_UPDATE_RESET:
    case ACTIONS.SERVICE_ITEM_DELETE_RESET:
    case ACTIONS.SERVICE_ITEM_FETCH_RESET:
      return {
        ...state,
        activeServiceItem: {
          serviceItem: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //================================== SERVICE_ITEM CREATE START ==================================//
    case ACTIONS.SERVICE_ITEM_CREATE_START:
      return {
        ...state,
        activeServiceItem: {
          serviceItem: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.SERVICE_ITEM_CREATE_FAILURE:
      return {
        ...state,
        activeServiceItem: {
          serviceItem: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.SERVICE_ITEM_CREATE_SUCCESS:
      return {
        ...state,
        serviceItemsList: {
          ...state.serviceItemsList,
          serviceItems: state.serviceItemsList.serviceItems.concat(action.payload),
          error: null,
          loading: false,
        },
        activeServiceItem: {
          serviceItem: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    //==================================  SERVICE_ITEM CREATE END  ==================================//
    //================================== SERVICE_ITEM UPDATE START ==================================//
    case ACTIONS.SERVICE_ITEM_UPDATE_START:
      return {
        ...state,
        activeServiceItem: {
          serviceItem: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.SERVICE_ITEM_UPDATE_FAILURE:
      return {
        ...state,
        activeServiceItem: {
          serviceItem: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.SERVICE_ITEM_UPDATE_SUCCESS:
      return {
        ...state,
        serviceItemsList: {
          ...state.serviceItemsList,
          serviceItems: state.serviceItemsList.serviceItems.map(perm => {
            if (perm.id === action.payload.id) {
              perm = action.payload;
            }
            return perm;
          }),
          error: null,
          loading: false,
        },
        activeServiceItem: {
          serviceItem: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  SERVICE_ITEM UPDATE END  ==================================//
    //================================== SERVICE_ITEM DELETE START ==================================//
    case ACTIONS.SERVICE_ITEM_DELETE_START:
      return {
        ...state,
        activeServiceItem: {
          serviceItem: action.payload,
          loading: true,
          error: null,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.SERVICE_ITEM_DELETE_FAILURE:
      return {
        ...state,
        activeServiceItem: {
          serviceItem: null,
          loading: false,
          error: action.payload,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.SERVICE_ITEM_DELETE_SUCCESS:
      return {
        ...state,
        serviceItemsList: {
          ...state.serviceItemsList,
          serviceItems: state.serviceItemsList.serviceItems.filter(
            serviceItem => serviceItem.id !== action.payload.id
          ),
          error: null,
          loading: false,
        },
        activeServiceItem: {
          serviceItem: action.payload,
          loading: false,
          error: null,
          mode: MODE.DELETE,
        },
      };
    //==================================  SERVICE_ITEM DELETE END  ==================================//
    //================================== SERVICE_ITEM FETCH START ==================================//
    case ACTIONS.SERVICE_ITEM_FETCH_START:
      return {
        ...state,
        activeServiceItem: {
          serviceItem: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.SERVICE_ITEM_FETCH_FAILURE:
      return {
        ...state,
        activeServiceItem: {
          serviceItem: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.SERVICE_ITEM_FETCH_SUCCESS:
      return {
        ...state,
        activeServiceItem: {
          serviceItem: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  SERVICE_ITEM FETCH END  ==================================//
    //================================== SERVICE_ITEMS FETCH START ==================================//
    case ACTIONS.SERVICE_ITEMS_FETCH_START:
      return {
        ...state,
        serviceItemsList: {
          serviceItems: [],
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case ACTIONS.SERVICE_ITEMS_FETCH_SUCCESS:
      return {
        ...state,
        serviceItemsList: {
          ...state.serviceItemsList,
          serviceItems: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case ACTIONS.SERVICE_ITEMS_FETCH_FAILURE:
      return {
        ...state,
        serviceItemsList: {
          ...state.serviceItemsList,
          serviceItems: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case ACTIONS.SERVICE_ITEMS_FETCH_RESET:
      return {
        ...state,
        serviceItemsList: {
          serviceItems: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  SERVICE_ITEMS FETCH END  ==================================//
    default:
      return state;
  }
};
