import axios from 'axios';
import promise from 'promise';

import config from './config';

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

const onAccessTokenFetched = accessToken => {
  subscribers = subscribers.filter(callback => callback(accessToken));
};

const addSubscriber = callback => {
  subscribers.push(callback);
};

const refreshToken = () => {
  const token = localStorage.getItem('refresh_token');
  return axios({
    method: 'POST',
    url: 'account/refresh-token',
    data: { refresh_token: token },
  });
};

const storeTokens = (accessToken, refreshToken) => {
  localStorage.setItem('token', accessToken);
  localStorage.setItem('refresh_token', refreshToken);
};

const initilizeHttpClient = () => {
  const instance = axios.create();
  instance.defaults.headers.common = {};
  axios.defaults.baseURL = config.API_BASE_URL;
  const lang = localStorage.getItem('lang') || config.defaultLanguage;
  const platform = config.platform;

  //configure request interceptors
  axios.interceptors.request.use(
    config => {
      // add request interceptors, like token, custom headers,...
      if (typeof localStorage.getItem('token') === 'string') {
        config.headers.authorization = 'Bearer ' + localStorage.getItem('token');
      }
      // accept-language header
      config.headers['Accept-Language'] = lang;

      if (!config.isExternalRequest && config.method !== 'OPTIONS') {
        config.headers['app-platform'] = platform;
      }

      return config;
    },
    error => {
      return promise.reject(error);
    }
  );

  // configure response interceptor
  axios.interceptors.response.use(
    response => {
      // do something with response, perhaps try to unify api responses
      return response;
    },
    error => {
      const {
        config,
        response: { status },
      } = error;
      const originalRequest = config;

      if (status === 401) {
        console.log('got 401');
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true;
          console.log('start refreshing');
          refreshToken()
            .then(response => {
              const { token, refresh_token } = response.data.data;
              console.log('got new tokens');
              storeTokens(token, refresh_token);
              isAlreadyFetchingAccessToken = false;
              onAccessTokenFetched(token);
            })
            .catch(err => {
              console.log('got refresh error');
              console.log(err);
              localStorage.clear();
              window.location.href = '/';
            });
        }

        const retryOriginalRequest = new Promise(resolve => {
          console.log('subscribe request');
          addSubscriber(token => {
            console.log('resolve request');
            console.log('use token:', token);
            originalRequest.headers.authorization = 'Bearer ' + token;
            resolve(axios(originalRequest));
          });
        });
        return retryOriginalRequest;
      }

      return Promise.reject(error);
    }
  );
};

export default initilizeHttpClient;
