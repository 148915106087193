export const ACTIONS = {
  ROLES_FETCH_START: 'ROLES_FETCH_START',
  ROLES_FETCH_SUCCESS: 'ROLES_FETCH_SUCCESS',
  ROLES_FETCH_FAILURE: 'ROLES_FETCH_FAILURE',
  ROLES_FETCH_RESET: 'ROLES_FETCH_RESET',

  ROLE_FETCH_START: 'ROLE_FETCH_START',
  ROLE_FETCH_SUCCESS: 'ROLE_FETCH_SUCCESS',
  ROLE_FETCH_FAILURE: 'ROLE_FETCH_FAILURE',
  ROLE_FETCH_RESET: 'ROLE_FETCH_RESET',

  ROLE_CREATE_START: 'ROLE_CREATE_START',
  ROLE_CREATE_SUCCESS: 'ROLE_CREATE_SUCCESS',
  ROLE_CREATE_FAILURE: 'ROLE_CREATE_FAILURE',
  ROLE_CREATE_RESET: 'ROLE_CREATE_RESET',

  ROLE_UPDATE_START: 'ROLE_UPDATE_START',
  ROLE_UPDATE_SUCCESS: 'ROLE_UPDATE_SUCCESS',
  ROLE_UPDATE_FAILURE: 'ROLE_UPDATE_FAILURE',
  ROLE_UPDATE_RESET: 'ROLE_UPDATE_RESET',

  ROLE_DELETE_START: 'ROLE_DELETE_START',
  ROLE_DELETE_SUCCESS: 'ROLE_DELETE_SUCCESS',
  ROLE_DELETE_FAILURE: 'ROLE_DELETE_FAILURE',
  ROLE_DELETE_RESET: 'ROLE_DELETE_RESET',
};
