export const ACTIONS = {
  ASSETS_FETCH_START: 'ASSETS_FETCH_START',
  ASSETS_FETCH_SUCCESS: 'ASSETS_FETCH_SUCCESS',
  ASSETS_FETCH_FAILURE: 'ASSETS_FETCH_FAILURE',
  ASSETS_FETCH_RESET: 'ASSETS_FETCH_RESET',

  ASSET_FETCH_START: 'ASSET_FETCH_START',
  ASSET_FETCH_SUCCESS: 'ASSET_FETCH_SUCCESS',
  ASSET_FETCH_FAILURE: 'ASSET_FETCH_FAILURE',
  ASSET_FETCH_RESET: 'ASSET_FETCH_RESET',

  ASSET_CREATE_START: 'ASSET_CREATE_START',
  ASSET_CREATE_SUCCESS: 'ASSET_CREATE_SUCCESS',
  ASSET_CREATE_FAILURE: 'ASSET_CREATE_FAILURE',
  ASSET_CREATE_RESET: 'ASSET_CREATE_RESET',

  ASSET_UPDATE_START: 'ASSET_UPDATE_START',
  ASSET_UPDATE_SUCCESS: 'ASSET_UPDATE_SUCCESS',
  ASSET_UPDATE_FAILURE: 'ASSET_UPDATE_FAILURE',
  ASSET_UPDATE_RESET: 'ASSET_UPDATE_RESET',

  ASSET_DELETE_START: 'ASSET_DELETE_START',
  ASSET_DELETE_SUCCESS: 'ASSET_DELETE_SUCCESS',
  ASSET_DELETE_FAILURE: 'ASSET_DELETE_FAILURE',
  ASSET_DELETE_RESET: 'ASSET_DELETE_RESET',
};
