import axios from 'axios';

export default class AccountAPI {
  static login = (email, password) => {
    return axios({
      method: 'POST',
      url: `account/login`,
      data: { email, password },
    });
  };

  static inviteUser = user => {
    return axios({
      method: 'POST',
      url: `account/invite`,
      data: user,
    });
  };

  static inviteBulkUsers = userInvites => {
    return axios({
      method: 'POST',
      url: `account/bulk-invite`,
      data: { invites: userInvites },
    });
  };

  static forgotPassword = email => {
    return axios({
      method: 'POST',
      url: 'account/forgot-password',
      data: { email },
    });
  };

  static resetPassword = (token, password, password_confirm) => {
    return axios({
      method: 'POST',
      url: 'account/reset-password',
      data: { token, password, password_confirm },
    });
  };

  static acceptInvitation = (token, password, password_confirm) => {
    return axios({
      method: 'POST',
      url: 'account/accept-invite',
      data: { token, password, password_confirm },
    });
  };

  static confirmEmail = (email, token) => {
    return axios({
      method: 'POST',
      url: 'account/confirm-email',
      data: { email, token },
    });
  };

  static getProfile = () => {
    return axios({
      method: 'GET',
      url: `account/`,
    });
  };

  static updateProfile = data => {
    return axios({
      method: 'PUT',
      url: `account/`,
      data: data,
    });
  };
}
