import { ACTIONS } from '../constants/products';
import { MODE } from '../constants/mode';

const INITIAL_STATE = {
  productsList: { products: [], params: {}, pager: null, error: null, loading: false },
  activeProduct: { product: null, error: null, mode: null },
};

export const products = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.PRODUCT_CREATE_RESET:
    case ACTIONS.PRODUCT_UPDATE_RESET:
    case ACTIONS.PRODUCT_DELETE_RESET:
    case ACTIONS.PRODUCT_FETCH_RESET:
      return {
        ...state,
        activeBicycleType: {
          bicycleType: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //================================== PRODUCT CREATE START ==================================//
    case ACTIONS.PRODUCT_CREATE_START:
      return {
        ...state,
        activeProduct: {
          product: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.PRODUCT_CREATE_FAILURE:
      return {
        ...state,
        activeProduct: {
          product: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.PRODUCT_CREATE_SUCCESS:
      return {
        ...state,
        productsList: {
          ...state.productsList,
          bicycleTypes: state.productsList.products.concat(action.payload),
          error: null,
          loading: false,
        },
        activeProduct: {
          product: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    //==================================  PRODUCT CREATE END  ==================================//
    //================================== PRODUCT UPDATE START ==================================//
    case ACTIONS.PRODUCT_UPDATE_START:
      return {
        ...state,
        activeProduct: {
          product: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.PRODUCT_UPDATE_FAILURE:
      return {
        ...state,
        activeProduct: {
          product: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.PRODUCT_UPDATE_SUCCESS:
      return {
        ...state,
        productsList: {
          ...state.productsList,
          products: state.productsList.products.map(perm => {
            if (perm.id === action.payload.id) {
              perm = action.payload;
            }
            return perm;
          }),
          error: null,
          loading: false,
        },
        activeProduct: {
          product: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  PRODUCT UPDATE END  ==================================//
    //================================== PRODUCT DELETE START ==================================//
    case ACTIONS.PRODUCT_DELETE_START:
      return {
        ...state,
        activeProduct: {
          product: action.payload,
          loading: true,
          error: null,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.PRODUCT_DELETE_FAILURE:
      return {
        ...state,
        activeProduct: {
          product: null,
          loading: false,
          error: action.payload,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.PRODUCT_DELETE_SUCCESS:
      return {
        ...state,
        productsList: {
          ...state.productsList,
          products: state.productsList.products.filter(
            bicycleType => bicycleType.id !== action.payload.id
          ),
          error: null,
          loading: false,
        },
        activeProduct: {
          product: action.payload,
          loading: false,
          error: null,
          mode: MODE.DELETE,
        },
      };
    //==================================  PRODUCT DELETE END  ==================================//
    //================================== PRODUCT FETCH START ==================================//
    case ACTIONS.PRODUCT_FETCH_START:
      return {
        ...state,
        activeProduct: {
          product: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.PRODUCT_FETCH_FAILURE:
      return {
        ...state,
        activeProduct: {
          product: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.PRODUCT_FETCH_SUCCESS:
      return {
        ...state,
        activeProduct: {
          product: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  PRODUCT FETCH END  ==================================//
    //================================== PRODUCTS FETCH START ==================================//
    case ACTIONS.PRODUCTS_FETCH_START:
      return {
        ...state,
        productsList: {
          products: [],
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case ACTIONS.PRODUCTS_FETCH_SUCCESS:
      return {
        ...state,
        productsList: {
          ...state.productsList,
          products: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case ACTIONS.PRODUCTS_FETCH_FAILURE:
      return {
        ...state,
        productsList: {
          ...state.productsList,
          products: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case ACTIONS.PRODUCTS_FETCH_RESET:
      return {
        ...state,
        productsList: {
          products: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  PRODUCTS FETCH END  ==================================//
    default:
      return state;
  }
};
